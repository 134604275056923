import React from 'react';
import Login from '../Screen/Auth/Login';
import { Navigate } from 'react-router-dom';

const PrivateRoute = [
  { path: '/login', element: <Login /> },
  { path: '/', element: <Navigate to="/login" replace /> },
  // { path: '/register', element: <RegisterComponent /> },
];

export default PrivateRoute;

