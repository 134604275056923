// import React, { useMemo, useState } from 'react';
// import { Navigate, Outlet } from 'react-router-dom';
// import UploadImages from "../Screen/uploadImages";
// import Home from "../Screen/home";
// import PublicGroup from '../Screen/Groups/PublicGroup';
// import SubscriptionPay from '../Screen/payment';
// import PhotoGrapherLayout from '../Layout/photographer/PhotographerLayout';
// import JoinedAlbum from '../Screen/Groups/joinedAlbum';
// import ViewImages from '../Screen/viewImages';
// import Profile from '../Screen/Header/Profile/profile';
// import DeleteImages from '../Screen/deleteImage';
// import UsersMultipers from '../Screen/Header/Profile/UsersMultipers';





// const Pagesroute =[ {
//     path: '/',
//     element: (
//       <PhotoGrapherLayout/>
//     ),
//     children: [
//       { path: '/home',  element:<Home />,index:true},
//       { path: '/upload', element: <UploadImages />},
//       { path:'/joinedAlbum', element:<JoinedAlbum/>},
//       { path: '/publicGroup', element:<PublicGroup/> },
//       { path: '/subscription', element:<SubscriptionPay/> },
//       { path:'/ViewImages',  element:<ViewImages/>},
//       { path:'/DeleteImages',  element:<DeleteImages/>},
//       { path: '/subscription', element:<SubscriptionPay/> },
//       { path: '/profile', element:<Profile/> },
//       { path: '/Adduser', element:<UsersMultipers/>  }
//     ],
//   }
// ];

// export default Pagesroute;

// // export { PrivateRoute };


import React from 'react';
import { Outlet } from 'react-router-dom';
import UploadImages from "../Screen/uploadImages";
import Home from "../Screen/home";
import PublicGroup from '../Screen/Groups/PublicGroup';
import SubscriptionPay from '../Screen/payment';
import PhotoGrapherLayout from '../Layout/photographer/PhotographerLayout';
import JoinedAlbum from '../Screen/Groups/joinedAlbum';
import ViewImages from '../Screen/viewImages';
import Profile from '../Screen/Header/Profile/profile';
import DeleteImages from '../Screen/deleteImage';
import UsersMultipers from '../Screen/Header/Profile/UsersMultipers';
import PhotographersUploadImages from '../Screen/photographersUploadImage';
import AllAlbumImages from '../viewAlbum/viewalbum';
import EventForm from '../Screen/Groups/EventForm';
// import Carousel from '../Screen/Component/CarouselView';
// import FeedbackModal from '../Screen/Header/feedBack';

// const PhotographerRoutes = [
//   {
//     path: '/',
//     element: <PhotoGrapherLayout />,
//     children: [
//       { path: '/',element: <Home />, index: true },
//       { path: '/home', element: <Home /> },
//       { path: '/upload', element: <AllAlbumImages /> },
//       { path: '/joinedAlbum', element: <JoinedAlbum /> },
//       { path: '/publicGroup', element: <PublicGroup /> },
//       { path: '/subscription', element: <SubscriptionPay /> },
//       { path: '/ViewImages', element: <ViewImages /> },
//       { path: '/DeleteImages', element: <DeleteImages /> },
//       { path: '/profile', element: <Profile /> },
//       { path: '/Adduser', element: <UsersMultipers /> },
//     ],
//   },
// ];

const UserRoutes = [
  {
    path: '/',
    element: <PhotoGrapherLayout />,
    children: [
      { path: '/', element: <Home />, index: true },
      { path: '/home', element: <Home /> },
      { path: '/upload', element: <AllAlbumImages /> },
      // { path: '/upload', element: <PhotographersUploadImages /> },
      { path: '/joinedAlbum', element: <JoinedAlbum /> },
      { path: '/publicGroup', element: <PublicGroup /> },
      { path: '/subscription', element: <SubscriptionPay /> },
      { path: '/ViewImages', element: <ViewImages /> },
      { path: '/DeleteImages', element: <DeleteImages /> },
      { path: '/profile', element: <Profile /> },
    //  { path: '/carouselview', element:<Carousel/>},
      { path: '/Adduser', element: <UsersMultipers /> },
      { path: '/Eventform', element: <EventForm /> }

    ],
  },
];

export { UserRoutes };
