// Store/reducer.jsx
import { createSlice } from "@reduxjs/toolkit";
import { ADD_ADDUSERIMAGE_REQUEST, ADD_REGISTERDATA_REQUEST, CLEAR_REDUX_REQUEST } from "./actionType";

const userSlice = createSlice({
    name: "user",
    initialState: {
        user: null
    },
    reducers: {
        addProfileData: (state, action) => {
            console.log("actionImage2", action);
            state.user = action.payload;
        },
        updateProfile: (state, action) => {
            state.user = { ...state.user, ...action.payload };
        },
        updateProfileImage: (state, action) => {
            if (state.user) {
                state.user.UserImage = action.payload;
            }
        },
        logOut: (state) => {
            state.user = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ADD_ADDUSERIMAGE_REQUEST, (state, action) => {
                console.log("actionImage", action);
                state.user = { ...state.user, UserImage: action.payload.userImage, UserId: action.payload.userId };
            })
            .addCase(ADD_REGISTERDATA_REQUEST, (state, action) => {
                console.log("actionImage1", action);
                state.user = action.payload;
            })
            .addCase(CLEAR_REDUX_REQUEST, (state) => {
                state.user = null;
            });
    }
});

export const { addProfileData, updateProfile, updateProfileImage, logOut } = userSlice.actions;
export default userSlice.reducer;
