import {
  Button,
  Card,
  Typography,
  Grid,
  Box,
  TextField,
  Paper,
  Divider,
  Stack,
  isMuiElement,
  Select,
  MenuItem,
  IconButton,
  Alert,
  Container,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { styled, alpha, ThemeProvider } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router";
import { Clear } from "@mui/icons-material";
import guruji from "../../images/mountains.jpg";
import PhotoAlbumIcon from '@mui/icons-material/PhotoAlbum';
import createAlbumICon from "../../images/create-album-icon.svg";

import image1 from '../../images/frame.png';

import {
  GetEventApi,
  JoinEventApi,
  getJoinGroup,
  getProfileApi,
  getPublicGroup,
} from "../../API/Photographer";
import Loader from "../Component/loader";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import "../../css/Home.css";
import FilterListIcon from "@mui/icons-material/FilterList";
// import RangeDatePicker from '../component/DatePicker';
import RangeDatePicker from "../Component/DatePicker";

import { SearchField } from "../Component/SerachField";
import CardEvent from "../DynmicaComponment/CardEvent";
import { useSelector } from "react-redux";

export default function JoinedAlbum({ editEvent, handleDeleteOpen }) {
  const [open, setOpen] = React.useState(false);
  const [isCreate, setIsCreate] = React.useState(false);
  const [geteventName, setGetEventName] = useState([]);
  const [getJoinName, setGetJoinEventName] = useState([]);
  const [eventName, setEventName] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [eventProfile, setEventProfile] = useState("");
  const [error, setError] = useState("");
  const [load, setLoad] = useState(false);
  const [group, setGroup] = useState("Select");
  const [joinGroup, setJoinGroup] = useState("");
  const [status, setStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [copiedMap, setCopiedMap] = useState({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("sm"));
  const [grouping, setGrouping] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const defaultImage = "https://pbs.twimg.com/media/CW6hXDiUoAArGhx.jpg";
  const [loadss, setLoadss] = useState(false);
  const [recAlbum, setRecAlbum] = useState('');
  const [recentSearchedAlbums, setRecentSearchedAlbums] = useState([])


  const maxWidth = 1400;

  useEffect(() => {
    console.log('getJoinName:', getJoinName);
  }, [getJoinName]);
  const recentlyAddedAlbum = getJoinName?.[getJoinName.length - 2]; // Get the first album as recently added
  const previouslyJoinedAlbums = getJoinName?.slice(1);

  function handlegroup(data) {
    console.log(data);
    setGrouping(data);
  }
  const fileHandle = (event) => {
    setEventProfile(event?.target?.files[0]);
  };

  const getEvented = async (dates) => {

    try {

      const response = await GetEventApi(dates);
      console.log("hjhjh", response);

      setGetEventName(response?.data?.data || []);

    } catch (error) { }
  };

  //----------------------Date filter ------------------//



  // startDate=2024-03-11&endDate=2024-03-11
  //----------------End Date filter----------------------//

  const handleCopyFallback = (text) => {
    const input = document.createElement("textarea");
    input.value = text;
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
  };

  const handleCopylink = async (value, index) => {
    try {
      const referralLink = value;
      if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(referralLink);
        setCopiedMap((prevState) => ({
          ...prevState,
          [index]: true,
        }));
      } else {
        handleCopyFallback(referralLink);
        setCopiedMap((prevState) => ({
          ...prevState,
          [index]: true,
        }));
      }
    } catch (error) {
      console.error("Error copying text: ", error);
    } finally {
      setTimeout(() => {
        setCopiedMap((prevState) => ({
          ...prevState,
          [index]: false,
        }));
      }, 2000);
    }
  };

  const getProfile = async (data) => {
    const response = await getProfileApi();
    console.log("data", data);
    let datas = data;
    console.log("response123", response?.data?.data?.Events);
    getJoinEvents(response?.data?.data?.Events, datas);
  };

  const handleEvents = async () => {
    console.log("joinGroup", joinGroup);
    if (joinGroup === "") {
      setError("Album code is required");
    } else {
      const data = {
        eventCode: joinGroup,
      };

      const response = await JoinEventApi(data);
      console.log("response", response);
      console.log("response", response?.data?.Message);
      setStatus(response?.data?.ErrorCode);
      setErrorMessage(response?.data?.Message);
      if (response?.data?.ErrorCode === 0) {
        getProfile();

        setTimeout(() => {
          setOpen(false);
          setErrorMessage("");
          setJoinGroup("");
        }, 2000);

        // getProfile()
      }
    }
  };

  const handleChange = (e) => {
    setGroup(e.target.value);
    setError("");
    setErrorMessage("");
  };

  const handleOpenForJoin = () => {
    setIsCreate(false);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setIsCreate(false);
    setJoinGroup("");
    setError("");
    setGroup("Select");
    setErrorMessage("");
  };

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    borderColor: "#8CBAE8", // Set border color to black
    borderWidth: 1, // Set border width
    borderStyle: "solid", // Set border style
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "black",
    borderColor: "black",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
  }));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
  };

  const UserList = (item) => {
    const eventProps = item;
    navigate("/upload", { state: { eventProps } });
  };

  useEffect(() => {
    getProfile();
    getJoinEvents([], {});
  }, []);

  const getJoinEvents = async (item, datas) => {
    setLoadss(true);

    console.log("item", item);

    // Check if item is an array before calling map
    if (Array.isArray(item)) {
      const data = item.map((event) => event.eventCode);

      console.log("data123", datas);
      try {
        const response = await getJoinGroup(data, datas);
        console.log("response256", response);
        // const a=response?.data?.data[response?.data?.data.length-1];
        // const a = response?.data?.data[0];
        const a = response?.data?.data[response?.data?.data.length - 1];
        setRecAlbum(a)
        console.log("gggggggggggggggg", a);
        setGetJoinEventName(response?.data?.data || []);
        setRecentSearchedAlbums(response?.data?.recentJoin || []);
      } catch (error) {
        console.error("Error fetching join events:", error);
      } finally {
        setLoadss(false);
      }
    } else {
      console.error("Expected item to be an array, but got:", item);
    }
  };
  const profileData = useSelector((state) => state.user.user);



  const showButton = profileData?.userType === 'photographer';


  const onDate = (data) => {
    console.log("fsdfgfdgdfgdfgdf", data);
    const dat = data.split("#");
    console.log("hhh", dat);
    const dates = {
      startDate: dat[0],
      endDate: dat[1],
      search: "",
    };

    getEvented(dates);
    getJoinEvents([], dates);
  };



  const payment = () => {
    navigate("/subscription");

  };
  console.log(getJoinName, 'getJoinNamegetJoinName');

  //---------------sticky scroll Header -----------------------//
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setIsScrolling(true);
    let scrollTimeout;
    clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(() => {
      setIsScrolling(false);
    }, 200);
  };
  //-----------------------------------------------------------------------//
  //-----------------search onChange ----------------------------//

  const handleSearchChange = (data) => {
    console.log("data", data);
    setSearchValue(data);
    const datas = {
      startDate: "",
      endDate: "",
      search: data,
    };
    console.log(data);

    getProfile(datas);
  };

  //------------------------------------------------------------//
  const formatDate = (date) => {
    const today = new Date();
    const eventDate = new Date(date);
  
    const isToday = today.toDateString() === eventDate.toDateString();
    const isYesterday =
      today.getDate() - 1 === eventDate.getDate() &&
      today.getMonth() === eventDate.getMonth() &&
      today.getFullYear() === eventDate.getFullYear();
  
    const diffInTime = today.getTime() - eventDate.getTime();
    const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));
  
    const isThisMonth =
      today.getMonth() === eventDate.getMonth() &&
      today.getFullYear() === eventDate.getFullYear();
  
    const isThisYear =
      today.getFullYear() === eventDate.getFullYear();
  
    if (isToday) return "Today";
    if (isYesterday) return "Yesterday";
    if (diffInDays <= 7) return "Last 7 days";
    if (isThisMonth) return "This Month";
    if (isThisYear) return "This Year";
  
    return "Earlier";
  };
  
  const groupByDate = (events) => {
    return events.reduce((acc, event) => {
      const dateGroup = formatDate(event.eventDate);
      if (!acc[dateGroup]) {
        acc[dateGroup] = [];
      }
      acc[dateGroup].push(event);
      return acc;
    }, {});
  };
  console.log("sdfg");
  
  const [SuccessMessage, setSuccessMessage] = useState({
    message: "",
    status: null,
  });
  const [groupedEvents, setGroupedEvents] = useState({});

  useEffect(() => {
    setGroupedEvents(groupByDate(geteventName));
  }, [geteventName]);

  console.log('group event 1234', groupedEvents);

  const renderEvents = () => {
    return Object.keys(groupedEvents).map((date, index) => (
      <div key={date} style={{ maxWidth: `${maxWidth}px`, margin: "0 auto" }}>
        {/* {index > 0 && <Divider style={{ margin: '40px 0' }} />} */}{" "}
        <Typography
          variant="body5"
          style={{
            paddingLeft: "35px",
            textAlign: "center",
            fontWeight: "bold",
            marginTop: "40px",
            marginBottom: "0px",
            display: "flex",
            // backgroundColor: "red",
            width: "100px",
            height: "24px",
          }}
        >
          {date}
        </Typography>
        <Grid container spacing={1} marginTop={isMobileOrTablet ? 1 : 0}>
          {groupedEvents[date].map((item, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <ThemeProvider theme={theme}>
                <Stack direction="column" alignItems="center">
                  <div style={{
                    paddingTop: "5px",
                    height: "100%",
                    width: "100%", // Ensure the div takes full width
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "center",
                  }}>
                    <CardEvent
                      item={item}
                      onCardClick={UserList}
                      defaultImage={defaultImage}
                      style={{ width: "100%", maxWidth: "280px" }}
                    />
                  </div>
                </Stack>
              </ThemeProvider>
            </Grid>
          ))}
        </Grid>
      </div>
    ));
  };


  return (
    <div style={{ marginTop: "60px" }}>
      {load ? <Loader open={load} /> : null}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {isCreate ? null : (
          <>
            <Box sx={style}>


              {/* <Typography style={{ fontSize: "18px", color: "black" }}>
                Enter Album Code *
              </Typography>
              <Clear
                style={{
                  display: "flex",
                  position: "absolute",
                  right: 5,
                  top: 5,
                }}
                onClick={handleClose}
              /> */}

              {/* <TextField
                placeholder="Enter Album Code"
                style={{ width: "100%" }}
                value={joinGroup}
                onChange={(e) => {
                  setJoinGroup(e.target.value);
                  setError("");
                  setErrorMessage("");
                }}
              />
              {error === "Album code is required" ? (
                <Typography
                  style={{ color: "red", fontSize: "16px", textAlign: "left" }}
                >
                  {error}
                </Typography>
              ) : null} */}

              {/* <Button
                variant="contained"
                fullWidth
                style={{ marginTop: 20 }}
                onClick={handleEvents}
              >
                Join
              </Button> */}
            </Box>
          </>
        )}
      </Modal>
      {/* <div 
        style={{
          position: "sticky",
          top: "70px",
          zIndex: "999",
          width: "100%",
          transition: "top 0.3s",
          // top: isScrolling ? "-100px" : "70px",
        }}
      >
        <Card
          style={{ marginTop: 10, width: "100%", backgroundColor: "#F4FAFF" }}
          elevation={3}
        >
          {isMobileOrTablet ? (
            <Grid container spacing={2} sx={{ padding: "10px" }}>
              <Grid item xs={12} sm={12}>
                <Button
                  variant="outlined"
                  onClick={handleOpenForJoin}
                  fullWidth={isMobileOrTablet ? true : null}
                >
                  Join Album
                </Button>
              </Grid>
              <Grid item xs={12} sm={12}>
                {/* <RangeDatePicker filterDate={onDate} />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <IconButton aria-label="filter">
                  <FilterListIcon />
                </IconButton>
                <SearchField
                  width={"100%"}
                  searchProps={handleSearchChange}
                  placeholder="Search, Album name"
                />
                <TextField onChange={handleChange} />
              </Grid>
            </Grid>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: 20,
              }}
            >
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    {/* <Button
                      variant="outlined"
                      onClick={handleOpenForJoin}
                      fullWidth={isMobileOrTablet ? true : null}
                      style={{ marginLeft: 20 }}
                    >
                      Join Album
                    </Button> 
                  </Grid>
                </Grid>
              </div>
              <div>
                <Stack direction={"row"} gap={2}>

                  <RangeDatePicker width={'100%'} filterDate={onDate} />
                  <SearchField
                    width={"100%"}
                    searchProps={handleSearchChange}
                    placeholder=""
                  />
                </Stack>
              </div>
            </div>
          )}
        </Card>
      </div> */}
      <div
        style={{
          position: "sticky",
          top: "60px",
          zIndex: "999",
          width: "100%",
          // border: "1px solid #D4D4D4",
        }}
      >
        <Card
          style={{
            marginTop: 10,
            width: "100%",
            backgroundColor: "#F4FAFF",
            // border: "1px solid #D4D4D4",
          }}
          elevation={0}
        >
          {isMobileOrTablet ? (
            <Grid container spacing={2} sx={{ padding: "10px" }}>
              <Grid item xs={12} sm={12}></Grid>
              <Grid item xs={12} sm={12}>
                <RangeDatePicker width={"100%"} filterDate={onDate} />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                {/* {showButton && (<Button
                  height="20px"
                  fullWidth
                  variant="text"
                  // onClick={handleOpenForCreate}
                  style={{
                    textTransform: "none",
                    fontWeight: "600",
                    backgroundColor: "white",
                    border: "1px solid #D4D4D4",
                  }}
                >
                  <img
                    style={{ marginRight: "10px" }}
                    src={createAlbumICon}
                    alt=""
                    srcset=""
                  />
                  Create Album
                </Button>)} */}


                <SearchField
                  width={"400px"}
                  searchProps={handleSearchChange}
                  placeholder=""
                />
                {/* <TextField onChange={handleChange} /> */}
              </Grid>
            </Grid>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",

                justifyContent: "space-between",
                padding: 5,
                // width: "100%"
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: 10,
                }}
              >
                <Grid >
                  {/* {showButton && (<Button
                    height="20px"
                    fullWidth
                    variant="text"
                    // onClick={handleOpenForCreate}
                    style={{
                      textTransform: "none",
                      fontWeight: "600",
                      backgroundColor: "white",
                      border: "1px solid #D4D4D4",
                    }}
                  >
                    <img
                      style={{ marginRight: "10px" }}
                      src={createAlbumICon}
                      alt=""
                      srcset=""
                    />
                    Create Album
                  </Button>)} */}

                </Grid>
              </div>
              <div>
                <Stack
                  direction={"row"}
                  gap={2}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                    padding: 10,
                  }}
                >
                  {" "}
                  <RangeDatePicker width={"100%"} filterDate={onDate} />
                  <SearchField
                    width="400px"
                    searchProps={handleSearchChange}
                    // placeholder="Search, Album name"
                    style={{ backgroundColor: "white" }}
                  />
                </Stack>
              </div>
            </div>
          )}
        </Card>
        {/* <h2>sdfghj</h2> */}
        {SuccessMessage.status && (
          <Alert
            severity={SuccessMessage.status ? "success" : "error"}
            style={{ marginBottom: 10 }}
          >
            {SuccessMessage.message}
          </Alert>
        )}
      </div>

      <Container>
        <Stack spacing={2} alignItems={isMobile ? 'flex-start' : 'center'}>
          {/* First Section */}
          <Stack direction="column" alignItems={isMobile ? 'flex-start' : 'center'}>
            {/* <PhotoAlbumIcon fontSize="large" /> */}
            <Typography
              variant="h4"
              component="h1"
              sx={{
                fontSize: '24px',
                fontWeight: '600',
                color: '#3974CB',
                mt: 2
              }}
            >
              Private Album
            </Typography>
          </Stack>

          {/* Second Section */}
          <Typography
            variant="body1"
            sx={{
              fontSize: '16px',
              textAlign: isMobile ? 'left' : 'center',
              mt: 5,
              mb: 7,
              maxWidth: 800
            }}
          >
            To join the private group, enter the Album code given by the Album owner. This private album contains special photos or events with Gurudev and is only for those with access.
          </Typography>

          {errorMessage ? (
            <Alert
              severity={status === 0 ? "success" : "error"}
              onClose={() => handleClose()}
            >
              {errorMessage}
            </Alert>
          ) : null}
          {error === "Album code is required" && (
            <Alert
              severity="error"
              style={{ fontSize: "14px" }}
              onClose={() => handleClose()}
            >
              {error}
            </Alert>
          )}

          <Box
            sx={{
              marginTop: "50px",
              fontFamily: "sans-serif",
              height: { sm: '16.85rem' },
              width: { xs: '100%', sm: '32rem' },
              backgroundImage: `url(${image1})`,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderRadius: '16px',
              // boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
              // padding: { xs: '20px', sm: '30px' },
            }}
          >
            <Typography
              variant="body2"
              sx={{
                pt: 3,
                fontWeight: "400",
                width: { xs: '70%', sm: '180px' },
                height: "24px",
                marginTop: "10px",
                fontSize: '16px',
                textAlign: 'center',
                color: '#ffffff',
              }}
            >
              Enter Access Code *
            </Typography>

            <TextField
              variant="outlined"
              sx={{
                borderRadius: '5px',
                backgroundColor: '#ffffff',
                width: { xs: '80%', sm: '350px' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: 'none',
                  },
                  '&:hover fieldset': {
                    border: 'none',
                  },
                  '&.Mui-focused fieldset': {
                    border: 'none',
                  },
                },
                '& input': {
                  color: '#000000',
                },
                marginBottom: "20px",
              }}
              value={joinGroup}
              onChange={(e) => {
                setJoinGroup(e.target.value);
                setError("");
                setErrorMessage("");
              }}
            />

            <Button
              variant="contained"
              color="primary"
              sx={{
                height: "45px",
                width: { xs: '20%', sm: '105px' },
                borderRadius: '5px',
                backgroundColor: '#022757',
                color: '#ffff',
                marginBottom: "20px"

              }}
              onClick={handleEvents}
            >
              View
            </Button>
          </Box>
        </Stack>
      </Container>


      <div style={{ marginTop: "10px" }}>
        {loadss ? (
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
            <CircularProgress color="inherit" />
          </div>
        ) : (
          <>
            {recentSearchedAlbums && recentSearchedAlbums.length > 0 && (
              <div style={{ maxWidth: `${maxWidth}px`, margin: "0 auto" }}>
                <Typography style={{ marginTop: 20, fontWeight: "bold" }}>
                  Recent Searched Private Albums
                </Typography>
                <Grid container spacing={2} marginTop={isMobileOrTablet ? 1 : 0}>
                  {recentSearchedAlbums.map((item, index) => (  
                    <Grid item xs={12} sm={6} md={3} key={index}>
                      <ThemeProvider theme={theme}>
                        <Stack direction="column" alignItems="center">
                          <div style={{ paddingTop: "5px", height: "100%", width: "100%", overflow: "hidden", display: "flex", justifyContent: "center" }}>
                            <CardEvent
                              item={item}
                              onCardClick={UserList}
                              defaultImage={defaultImage}
                              style={{ width: "100%", maxWidth: "280px" }}
                            />
                          </div>
                        </Stack>
                      </ThemeProvider>
                    </Grid>
                  ))}
                </Grid>
              </div>
            )}

            {previouslyJoinedAlbums && previouslyJoinedAlbums.length > 0 && (
              <div style={{ maxWidth: `${maxWidth}px`, margin: "0 auto" }}>
                <Typography style={{ marginTop: 20, fontWeight: "bold" }}>
                  Previously Joined
                </Typography>
                <Grid container spacing={2} marginTop={isMobileOrTablet ? 1 : 0}>
                  {previouslyJoinedAlbums.map((item, index) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                      <ThemeProvider theme={theme}>
                        <Stack direction="column" alignItems="center">
                          <div style={{ paddingTop: "5px", height: "100%", width: "100%", overflow: "hidden", display: "flex", justifyContent: "center" }}>
                            <CardEvent
                              item={item}
                              onCardClick={UserList}
                              defaultImage={defaultImage}
                              style={{ width: "100%", maxWidth: "280px" }}
                            />
                          </div>
                        </Stack>
                      </ThemeProvider>
                    </Grid>
                  ))}
                </Grid>
              </div>
            )}
          </>
        )}
      </div>
      <div>{renderEvents(groupedEvents)}</div>

    </div>
  );
}
