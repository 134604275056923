// import { configureStore } from "@reduxjs/toolkit";
// import createSagaMiddleware from 'redux-saga';
// import userReducer from './reducer'; 
// import ProfileSaga from './saga';

// const sagaMiddleware = createSagaMiddleware();

// const store = configureStore({
//     reducer: {
//         user: userReducer
//     },
//     middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
// });

// sagaMiddleware.run(ProfileSaga);

// export default store;


import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; 
import userReducer from './reducer';
import ProfileSaga from './saga';

const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, userReducer);

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: {
        user: persistedReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false, // This line is important to avoid issues with non-serializable data in the state
    }).concat(sagaMiddleware),
});

sagaMiddleware.run(ProfileSaga);

const persistor = persistStore(store);

export { store, persistor };
