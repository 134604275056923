import { Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import Paper from "@mui/material/Paper";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const StickyTableHead = styled(TableHead)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  zIndex: 2,
  backgroundColor: 'white',
  maxHeight: '500px', 
  overflowY: 'auto', 
 }));
 




const TableCom = ({ children }) => {
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }} elevation={0}>
      <TableContainer sx={{ maxHeight: '500px' }}>
        <Table aria-label="sticky table">
          {children}
        </Table>
      </TableContainer>
    </Paper>
  );
 };
 
 

const THead = ({ children }) => {
  return <StickyTableHead>{children}</StickyTableHead>;
};

const TCell = ({ children, width }) => {
  return <TableCell width={width}>{children}</TableCell>
};

const TBody = ({ children }) => {
  return <TableBody>{children}</TableBody>;
};

// const Trow = ({ children }) => {
//   return <StyledTableRow>{children}</StyledTableRow>
// };
const Trow = ({ onClick, children }) => {
  return (
    <tr onClick={onClick} style={{ cursor: 'pointer' }}>
      {children}
    </tr>
  );
};

export { TBody, TCell, THead, TableCom, Trow };
