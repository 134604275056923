import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

const LoadingContainer = styled.div`
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  text-align: center;

  span {
    display: inline-block;
    margin: 0 0.1em; /* Adjusted margin for spacing */
  }
`;

const loading01 = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const LoadingAnimation = styled.span`
  animation: ${loading01} 1.4s infinite alternate;
  ${({ delay }) => `animation-delay: ${delay}s;`}
`;

const Loading = ({ text }) => {
  const [animationDelay, setAnimationDelay] = useState([]);

  useEffect(() => {
    // Set the delay for the animation after 1 second
    const delay = text.split('').map((_, index) => (index * 0.5) / text.length);
    setTimeout(() => {
      setAnimationDelay(delay);
    }, 1000);
  }, [text]);

  return (
    <LoadingContainer>
      {text.split('').map((char, index) => (
        <LoadingAnimation key={index} delay={animationDelay[index] || 0}>
          {char}
        </LoadingAnimation>
      ))}
    </LoadingContainer>
  );
};

export default Loading;
