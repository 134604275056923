import React from 'react';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { Typography, Alert } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { otpVerify } from '../../API/Auth';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { addProfileRequest } from '../../Store/action';

export default function Login2({ Login2Data, errorMessage, userEmail, moveNextStep }) {
  const [otp, setOtp] = React.useState('');
  const [errorMessages, setErrorMessages] = React.useState(errorMessage);
  const [status, setStatus] = React.useState('');
  const [isOtpValid, setIsOtpValid] = React.useState(false); // New state to track OTP validity
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('sm'));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = async (newValue) => {
    setErrorMessages("");
    const newValues = newValue.replace(/[^0-9]/g, '');
    setOtp(newValues);

    if (newValue.length === 6) {
      try {
        const data = {
          email: userEmail,
          otp: newValue
        };

        const response = await otpVerify(data);
        setStatus(response.status);
        if (response.status === 200) {
          setErrorMessages("");
          setIsOtpValid(true); // Set OTP as valid
          if (response?.data?.userType === null) {
            moveNextStep();
          } else {
            localStorage.clear();
            localStorage.setItem("accessToken", response?.data?.accessToken);
            localStorage.setItem("userId", response?.data?.data?._id);
            localStorage.setItem("email", response?.data?.data?.email);
            localStorage.setItem("profileImage", response?.data.data?.imageUrl);
            const photoGrpherExist = response?.data?.IsExist;
            const datas = { email: response?.data?.data?.email, photoGrpherExist };
             dispatch(addProfileRequest(datas));
            setErrorMessages("Logged in Successfully");

            setTimeout(() => {
              setErrorMessages('');
              navigate('/home');
            }, 1000);
          }
        } else {
          setIsOtpValid(false); // OTP is invalid
          setErrorMessages("Wrong OTP");
        }
      } catch (err) {
        setIsOtpValid(false); // OTP is invalid
        console.error(err);
        setErrorMessages(err.message);
      }
    } else {
      setIsOtpValid(false); // OTP length is not 6, so it's not valid
    }

    Login2Data(newValue);
  };

  return (
    <div>
      {errorMessage || errorMessages ? (
        <Alert severity={status === 200 ? "success" : "error"}>
          {errorMessage ? errorMessage : errorMessages}
        </Alert>
      ) : null}

      <div style={{ marginTop: 10 }}>
        <Typography style={{ textAlign: "center" }}>
          An OTP has been sent to <span style={{ fontWeight: "bold" }}>{userEmail}</span>
        </Typography>
        <div style={{ marginTop: 10 }}>
          <MuiOtpInput value={otp} onChange={handleChange} length={6} />
        </div>
      </div>
    </div>
  );
}
