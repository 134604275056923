import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../../Screen/Header/photoGrapherHeader';
import '../../css/AdminLayout.css';

function PhotoGrapherLayout() {

    return (
        <div className="AdminLayou-body">
            <div>
                <Header />
            </div>
            <div style={{ margin: '' }}>
                <Outlet />
            </div>
        </div>
    );
}

export default PhotoGrapherLayout;
