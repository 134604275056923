import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router";
import useMediaQuery from '@mui/material/useMediaQuery';


const GradientCard = styled(Card)(({ theme }) => {
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('sm'));
  const is768px = useMediaQuery(theme.breakpoints.down("780"));
  const is1024px = useMediaQuery(theme.breakpoints.down("1030"));


  return {
    background: "linear-gradient(135deg, #a8edea 0%, #fed6e3 100%)",
    borderRadius: "15px",
    padding: "24px",
    marginTop: isMobileOrTablet ? "15%" : is768px ? "10%" : "0%",

    marginRight: isMobileOrTablet ? "0%" : "0%",
    marginLeft: isMobileOrTablet ? "5%" : is768px ? "0%" : "27%",
    maxHeight: "450px",
    maxWidth: "450px",
  };
});

const GradientCardTwo = styled(Card)(({ theme }) => {
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('sm'));
  const is768px = useMediaQuery(theme.breakpoints.down("780"));

  return {
    background: "linear-gradient(135deg, #e0c3fc 0%, #8ec5fc 100%)",
    borderRadius: "15px",
    padding: "24px",
    marginBottom: isMobileOrTablet ? "10%" : is768px ? "40%" : "0%",
    marginLeft: isMobileOrTablet ? "5%" : "0%",
    marginRight: isMobileOrTablet ? "%" : is768px ? "0%" : "25%",
    maxHeight: "450px",
    maxWidth: "450px",
  };
});

const EventCards = () => {

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [eventType, setEventType] = useState();

  const navigate = useNavigate();

  const handleOpenForm = (type) => {
    setEventType(type);
    setIsFormOpen(true);
    navigate("/Eventform", { state: { eventType: type } });
  };
  // console.log(eventType, "eventTypeeventType");

  return (

    <Grid
      container
      spacing={3}
      justifyContent="center"
      alignItems="center"
      style={{
        minHeight: "100vh",
        maxWidth: "100vw",
        marginBottom: "",
        backgroundColor: "",
      }}
    >
      <Grid item xs={12} md={6} sm={12} display="flex" justifyContent="center" >
        <GradientCard >
          <CardContent>
            <Typography variant="subtitle1" color="textSecondary">
              Private Events{" "}
            </Typography>
            <Typography variant="h4" gutterBottom>
              Exclusive and Secure Group Events.{" "}
            </Typography>
            <Typography fontSize={"1rem"}>
              Private celebrations ideal for exclusive events like weddings,
              birthday parties, conferences, corporate events, and more.{" "}
            </Typography>
            <Box mt={2} display="flex" justifyContent="">
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleOpenForm("Private")}
              >
                Create Private
              </Button>
            </Box>
          </CardContent>
        </GradientCard>
      </Grid>

      <Grid item xs={12} md={6} display="flex" justifyContent="center">
        <GradientCardTwo>
          <CardContent>
            <Typography variant="subtitle1" color="textSecondary">
              Public Events.
            </Typography>
            <Typography variant="h4" gutterBottom>
              Connect & Collaborate Group Events{" "}
            </Typography>
            <Typography variant="body1">
              Public celebrations ideal for open events like festivals,
              community gatherings, and public conferences. Open access to join
              and participate.
            </Typography>
            <Box mt={2} display="flex" justifyContent="">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleOpenForm("Public")}
                style={{
                  // textTransform:"none"
                }}
              >
                Create Public
              </Button>
            </Box>
          </CardContent>
        </GradientCardTwo>
      </Grid>
    </Grid>
  );
};

export default EventCards;
