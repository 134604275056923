// import { HomeOutlined,UpCircleOutlined,FileAddOutlined,BookOutlined,AppstoreAddOutlined,UserOutlined,UserAddOutlined,CalendarOutlined, EditOutlined, FormOutlined} from '@ant-design/icons';

import { AppstoreAddOutlined, FileOutlined, FormOutlined } from "@ant-design/icons";
import { EditOutlined } from "@mui/icons-material";
import { FileIcon } from "@ant-design/icons";




const menuData = {
  id: 'group-dashboard',
  type: 'group',
  defaultOpenKey: 'dashboard',
  children: [
    {
      id: 'report',
      title: 'Dashboard',
      type: 'item',
      url: '/dailyReport',
      // icon: <FileOutlined/>,
      // breadcrumbs: false,
    },

    {
      id: 'registrationlist',
      title: 'Registration list',
      type: 'item',
      // url: '/addCard',
      // icon: <EditOutlined/>,
      children: [
        {
          id: 'sub-user-1',
          title: 'Photographer List',
          type: 'item',
          url: '/photographerList',
          // icon: <FormOutlined />,
        },
        {
          id: 'sub-user-2',
          title: 'User List',
          type: 'item',
          url: '/userList',
          // icon: <FormOutlined />,
        },
      ]
    },
    // {
    //     id: 'Create Packages',
    //     title: 'Subscription ',
    //     type: 'item',
    //     // url: '/addCard',
    //     // icon: <EditOutlined />,
    //     children: [
    //       {
    //         id: 'sub-user4',
    //         title: 'Photographer',
    //         type: 'item',
    //         url: '/createPackage',
    //         // icon: <FormOutlined />,
    //       },
    //       {
    //         id: 'sub-user5',
    //         title: 'User',
    //         type: 'item',
    //         url: '/createPackageUser',
    //         // icon: <FormOutlined />,
    //       },
    //     ]
    //   },

    {
      id: 'albums',
      title: 'Album List',
      type: 'item',
      url: '/allAlbums',
      // icon: <AppstoreAddOutlined />,
      // breadcrumbs: false,
    },

  ],
};

export default menuData;