import React, { useState, useEffect } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Button,
  Grid,
  Stack,
  Tooltip,
  useMediaQuery,
  useTheme,
  Avatar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearRedux } from "../../Store/action";
import mandala from "../../images/mandala-icon.svg";
import FeedbackModal from "../Component/feedBack";

const Header = ({ sidebarOpen }) => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false); 
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down(1025));
  const is768px = useMediaQuery(theme.breakpoints.down(770));

  const isLargeScreen = useMediaQuery('(min-width:1301px)');
  const is450px = useMediaQuery('(min-width:450px)');
  const is1024px = useMediaQuery('(min-width:1030px)');

  const rightheader = is1024px ? "30%" : is768px ? "28%" : is450px ? "40%" : "35%";
  const imageOpacity = isLargeScreen ? "30%" : is768px ? "0%" : "30%"
  const ismarginRight = is768px ? "30%" : "15%";

  const isTablet = useMediaQuery(theme.breakpoints.down(750));

  const profileData = useSelector((state) => state.user);

  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    const loc = location.pathname;
    if (loc === "/home") {
      setActiveTab("myAlbum");
    } else if (loc === "/publicGroup") {
      setActiveTab("public");
    } else if (loc === "/joinedAlbum") {
      setActiveTab("joined");
    } else {
      setActiveTab("");
    }
  }, [location.pathname]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const clearLocalStorage = () => {
    localStorage.clear();
    dispatch(clearRedux());
    navigate("/login");
  };

  const handleChange = (data) => {
    setActiveTab(data);
    if (data === "myAlbum") {
      navigate("/home");
    } else if (data === "public") {
      navigate("/publicGroup");
    } else if (data === "joined") {
      navigate("/joinedAlbum");
    }
  };

  const profilePage = () => {
    navigate("/profile");
    setAnchorElUser(null);
  };

  const openFeedbackModal = () => {
    setIsFeedbackModalOpen(true);
    setAnchorElUser(null);
  };

  const closeFeedbackModal = () => {
    setIsFeedbackModalOpen(false);
  };

  return (
    <AppBar position="fixed" sx={{ backgroundColor: "#297EFF", padding: "0" }}>
      <Toolbar>
        <Grid container spacing={2} alignItems="center">
          <Grid container xs={isMobileOrTablet ? 8 : 4}>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                marginTop: "10px",
                paddingLeft: isMobileOrTablet ? "10px" : "0px",
              }}
              onClick={() => navigate("/home")}
            >
              <Typography
                component="div"
                sx={{
                  fontSize: isMobileOrTablet ? "1rem" : "1.5rem",
                  paddingLeft: isMobileOrTablet ? "10px" : "20px",
                  color: "#FFF",
                  marginRight: "5px",
                }}
              >
                FindMyPik
              </Typography>
              <Typography
                variant="caption"
                component="div"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  height: isMobileOrTablet ? "0.7rem" : "0.7rem",
                  width: "2rem",
                  border: "1px solid #FFF",
                  borderRadius: "10px",
                  padding: isMobileOrTablet ? "1px" : "2px 5px",
                  fontWeight: "bold",
                  color: "#FFF",
                  fontSize: isMobileOrTablet ? "8px" : "8px",
                  lineHeight: "1rem", 
                }}
              >
                BETA
              </Typography>
            </Grid>
          </Grid>

          {!isMobileOrTablet && (
            <Grid item xs={4} container justifyContent="center">
              <img
                style={{
                  position: "absolute",
                  top: "1%",
                  left: rightheader,
                  opacity: imageOpacity,
                }}
                src={mandala}
                alt=""
              />
            </Grid>
          )}

          <Grid item xs={isMobileOrTablet ? 4 : 4}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {isMobileOrTablet ? (
                <>
                  <Grid item xs={4} container justifyContent="center">
                    <img
                      style={{
                        position: "absolute",
                        marginRight: ismarginRight,
                        top: "1%",
                        left: rightheader,
                        opacity: imageOpacity
                      }}
                      src={mandala}
                      alt=""
                    />
                  </Grid>

                  <Grid item xs={4} container justifyContent="center">
                    <img
                      style={{
                        position: "absolute",
                        marginRight: ismarginRight,
                        top: "10%",
                        left: rightheader,
                        opacity: 0,
                      }}
                      src={mandala}
                      alt=""
                    />
                  </Grid>
                  <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar
                        src={profileData?.user?.UserImage}
                        style={{
                          height: 45,
                          width: 45,
                          cursor: "pointer",
                          borderRadius: "50%",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={handleOpenNavMenu}
                    sx={{
                      marginLeft: "4px",
                      paddingRight: isMobileOrTablet ? "10px" : "0px",
                    }}
                  >
                    <MenuIcon />
                  </IconButton>
                </>
              ) : (
                <>
                  <Stack direction={"row"}>
  <Button
    sx={{
      color: "#FFF",
      fontSize: "1rem",  
      width: "7rem",  
      maxHeight: "40px",
      textTransform: "none",
      backgroundColor: activeTab === "myAlbum" ? "#4992FF" : "transparent",
      "&:hover": {
        backgroundColor: "#4992FF",
        color: "#FFF",
      },
    }}
    onClick={() => handleChange("myAlbum")}
  >
    My Uploads
  </Button>
  <Button
    sx={{
      color: "#FFF",
      fontSize: "1rem",  
      width: "8rem",  
      marginLeft: "10px",
      maxHeight: "40px",
      textTransform: "none",
      backgroundColor: activeTab === "public" ? "#4992FF" : "transparent",
      "&:hover": {
        backgroundColor: "#4992FF",
        color: "#FFF",
      },
    }}
    onClick={() => handleChange("public")}
  >
    Public Albums
  </Button>
  <Button
    sx={{
      color: "#FFF",
      fontSize: "1rem",  
      width: "8rem",  
      marginLeft: "10px",
      maxHeight: "40px",
      display: "flex",
      alignItems: "center",
      textTransform: "none",
      backgroundColor: activeTab === "joined" ? "#4992FF" : "transparent",
      "&:hover": {
        backgroundColor: "#4992FF",
        color: "#FFF",
      }, // <- Closing curly brace here
    }}
    onClick={() => handleChange("joined")}
  >
    Private Album
  </Button>
</Stack>


                  <Tooltip title="Open settings">
                    <IconButton
                      onClick={handleOpenUserMenu}
                      sx={{ p: 0, marginLeft: "10px" }}
                    >
                      <Avatar
                        src={profileData?.user?.UserImage}
                        style={{
                          height: 45,
                          width: 45,
                          cursor: "pointer",
                          borderRadius: "50%",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </Box>
          </Grid>
        </Grid>

        {/* User Menu */}
        <Menu
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {["Profile", "Logout", "Feedback"].map((setting) => (
            <MenuItem
              key={setting}
              onClick={() => {
                if (setting === "Logout") {
                  clearLocalStorage();
                } else if (setting === "Profile") {
                  profilePage();
                  setAnchorElUser(null); 
                } else if (setting === "Feedback") {
                  openFeedbackModal(); 
                }
              }}
            >
              {setting}
            </MenuItem>
          ))}
        </Menu>

        
        <Menu
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
        >
          <MenuItem
            onClick={() => {
              handleChange("myAlbum");
              handleCloseNavMenu(); 
            }}
          >
            My Uploads
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleChange("public");
              handleCloseNavMenu(); 
            }}
          >
            Public Albums
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleChange("joined");
              handleCloseNavMenu(); 
            }}
          >
            Private Album
          </MenuItem>
        </Menu>
      </Toolbar>

     
      {isFeedbackModalOpen && (
        <FeedbackModal open={isFeedbackModalOpen} onClose={closeFeedbackModal} />
      )}
    </AppBar>
  );
};

export default Header;
