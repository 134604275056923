import {
  Button,
  Card,
  Typography,
  Grid,
  Box,
  TextField,
  Paper,
  Divider,
  Stack,
  isMuiElement,
  Select,
  MenuItem,
  IconButton,
  Alert,
  CircularProgress,
  FormControlLabel,
  Switch,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { styled, alpha, ThemeProvider } from "@mui/material/styles";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router";
import guruji from "../../images/mountains.jpg";

import {
  CreateEvent,
  EditEvent,
  EditEvents,
  GetEventApi,
  JoinEventApi,
  deleteMyEvent,
  getPrivateGroup,
  getProfileApi,
} from "../../API/Photographer";
import CollectionsIcon from "@mui/icons-material/Collections";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import PublicIcon from "@mui/icons-material/Public";
import ApartmentIcon from "@mui/icons-material/Apartment";
import GroupIcon from "@mui/icons-material/Group";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ScheduleIcon from "@mui/icons-material/Schedule";

// import Loader from '../Component/loader';
import Loader from "../Component/loader";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import "../../css/Home.css";
import {
  Clear,
  Delete,
  Edit,
  SettingsCellRounded,
  UploadFileRounded,
} from "@mui/icons-material";
import DeleteModal from "../Component/deleteModal";
import { SearchField } from "../Component/SerachField";
import RangeDatePicker from "../Component/DatePicker";
import FilterListIcon from "@mui/icons-material/FilterList";
import CardEvent from "../DynmicaComponment/CardEvent";
import MyCardEvent from "../DynmicaComponment/myCardEvent";
import createAlbumICon from "../../images/create-album-icon.svg";
import createAlbumIConwhite from "../../images/create-album-icon - white.svg";
import asd from "../../images/date-time-slot-icon.svg";
import EventCards from "../Events/EventCards";
import deleteicon from "../../images/edit-icon.svg";
import { useLocation } from "react-router"; // Import useNavigate and useLocation
import NotificationModal from "../Component/Notification";

export default function Groups() {
  const [SuccessMessage, setSuccessMessage] = useState({
    message: "",
    status: null,
  });

  const [open, setOpen] = React.useState(false);
  const [editopen, setEditOpen] = React.useState(false);
  const [cardopen, setCardOpen] = React.useState(false);

  const [isCreate, setIsCreate] = React.useState(false);
  const [geteventName, setGetEventName] = useState([]);

  const [eventName, setEventName] = useState("");
  const [editedData, setEditedData] = useState("");
  const [venue, setVenue] = useState("");
  const [albumName, setAlbumName] = useState("");
  // const [location, setLocation] = useState('');
  const [country, setCountry] = useState("India");
  const [cityName, setCityName] = useState("Bengaluru, India");
  const photographerEmail = localStorage.getItem("email");
  const [eventDate, setEventDate] = useState("");
  const [eventProfile, setEventProfile] = useState("");
  const [area, setArea] = useState("Select");
  const [venueAl, setVenueAl] = useState("Select");
  const [error, setError] = useState("");
  const [load, setLoad] = useState(false);
  const [isEditData, setIsEdit] = useState(false);
  const [group, setGroup] = useState("Select");
  const [slot, setSlot] = useState("Select");
  const [joinGroup, setJoinGroup] = useState("");
  const [status, setStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [copiedMap, setCopiedMap] = useState({});
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("sm"));
  const inIpdaAir = useMediaQuery(theme.breakpoints.down("820"));
  const [filterDate, setFilterDate] = useState("");
  const [opens, setOpens] = useState(false);
  const [deleteItem, setDeleteItem] = useState("");
  const [grouping, setGrouping] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [loads, setLoads] = useState(false);
  const [loadss, setLoadss] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const is1024px = useMediaQuery(theme.breakpoints.down("1030px"));

  const [eventCode, setEventcode] = useState("");
  const defaultImage = "https://pbs.twimg.com/media/CW6hXDiUoAArGhx.jpg";
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const [file, setFile] = useState(null);
  const [isBanner, setIsBanner] = useState(false);
  const [bannerImage, setBannerImage] = useState(null);
  const [AlbumImage, setAlbumImage] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);

  const [bannerTitle, setBannerTitle] = useState("");

  function handlegroup(data) {
    console.log(data);
    setGrouping(data);
  }

  const fileHandle = (event) => {
    setErrorMessage("");
    const file = event?.target?.files[0];
    //  setEventProfile(event?.target?.files[0])
    console.log("file", file);
    if (file) {
      const fileType = file.type;

      if (fileType.startsWith("image/")) {
        setEventProfile(file);
      } else {
        setEventProfile(file);
        setErrorMessage("Please select an image file.");
      }
    }
  };

  console.log("groupe", group);
  const location = useLocation(); // Initialize location
  const searchParams = new URLSearchParams(location.search);
  const eventType = location.state?.eventType;

  const editEvent = async (item) => {
    setIsEdit(true);
    console.log("Editing Item:", item);
    setEditedData(item);

    setEventName(item);
    setVenue(item?.name);
    setGroup(item?.eventType);
    setAlbumName(item?.albumName);
    setAlbumImage(item?.imageUrl);
    setBannerImage(item?.bannerImage);
    setVenueAl(item?.venueAlias);
    setSlot(item?.slot);
    setCountry(item?.city);
    setEventDate(item?.date);
    setEventcode(item?.eventCode);
    setIsBanner(item?.bannerImage? true : false)

    const date = item.eventDate;
    const parts = date.split("-");
    const year = parts[0];
    const month = parts[1];
    const day = parts[2];
    const formattedDate = `${year}-${month}-${day}`;
    console.log("ASDASDASDasdasd");
    setEventDate(formattedDate);
    setEditOpen(true);

    // // Call the EditEvents API function
    // try {
    //   const response = await EditEvents(item);
    //   console.log("API Response:", response);
    //   // Handle the response as needed
    // } catch (error) {
    //   console.error("API Error:", error);
    //   // Handle the error as needed
    // }
  };

  const handleSlotChange = (event) => {
    setSlot(event.target.value);
    setErrorMessage("");
    // if (event.target.value === "Select") {
    //   setError("Slot is required");
    // } else {
    //   setError("");
    // }
  };

  const [citys, countrys] = cityName.split(", ");

  const handleEventSubmit = async () => {
    const formData = new FormData();

    try {
      // Validation for album type, album name, and other required fields
      if (group === "Select") {
        setErrorMessage("Album type is required");
      } else if (albumName.trim() === "" && !isEditData) { // Skip validation for album name when editing
        setErrorMessage("Album name is required");
      } else if (venue.trim() === "") {
        setErrorMessage("Venue is required");
      } else if (venueAl === "Select" && !isEditData) { // Skip validation for venue category when editing
        setErrorMessage("Venue category is required");
      } else if (slot === "Select") {
        setErrorMessage("Slot is required");
      } else if (cityName === "Select") {
        setErrorMessage("Place is required");
      } else if (eventDate === "") {
        setErrorMessage("Event date is required");
      } else {
        setLoads(true);
        setError("");
        setLoad(true);
        let response = "";

        const userData = {
          imageUrl: AlbumImage,
          name: venue,
          eventType: group,
          albumName: albumName,
          eventDate: eventDate,
          photographerEmail,
          city: citys,
          slot: slot,
          venueAlias: venueAl,
          area: area,
          country: countrys,
          eventCode: eventCode,
        };

        formData.append("data", JSON.stringify(userData));
        formData.append("file", AlbumImage);
        if(bannerImage){
          formData.append("bannerImage", bannerImage);
        }
        // bannerImage
        // if (eventProfile) {
        //   formData.append("file", eventProfile, eventProfile?.name);
        // }

        if (isEditData) {
          // Assume `eventCode` is available in `editedData`
          formData.append("eventCode", editedData.eventCode);
          response = await EditEvents(formData);
          console.log("dddddd",response.status);
          if(response.status === 200){
            setEditOpen(false);
          }
          
        } else {
          response = await CreateEvent(formData);
        }

        setLoad(false);
        setErrorMessage(response?.data?.Message);
        setStatus(response?.status);

        if (response?.status === 200) {
          // Reset form fields
          setEventName("");
          setEventDate("");
          setGroup("Select");
          setSlot("");
          setAlbumName("");
          setArea("");
          setVenue("");
          setVenueAl("Select");
          setCountry("");
          setCityName("Select");
          setEventProfile("");

          setTimeout(() => {
            setOpen(false);
            setErrorMessage("");
            setStatus("");
            setIsCreate(false);
          }, 2000);
        } else {
          setErrorMessage(response?.response?.data?.Message);
        }

        getEvent();
      }
    } catch (err) {
      console.log(err);
      setErrorMessage("An error occurred while creating the event.");
    } finally {
      setLoads(false);
    }
  };


  const handleCopyFallback = (text) => {
    const input = document.createElement("textarea");
    input.value = text;
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
  };

  const handleCopylink = async (value, index) => {
    try {
      const referralLink = value;
      if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(referralLink);
        setCopiedMap((prevState) => ({
          ...prevState,
          [index]: true,
        }));
      } else {
        handleCopyFallback(referralLink);
        setCopiedMap((prevState) => ({
          ...prevState,
          [index]: true,
        }));
      }
    } catch (error) {
      console.error("Error copying text: ", error);
    } finally {
      setTimeout(() => {
        setCopiedMap((prevState) => ({
          ...prevState,
          [index]: false,
        }));
      }, 2000);
    }
  };

  const isSmallScreen = useMediaQuery("(max-width:750px)");
  const getProfile = async () => {
    const response = await getProfileApi();

    console.log("response123", response?.data?.data?.Events);
    // getJoinEvents(response?.data?.data?.Events)
  };
  const handleEvents = async () => {
    console.log("joinGroup", joinGroup);
    if (joinGroup === "") {
      setError("Album code is required");
    } else {
      const data = {
        eventCode: joinGroup,
      };

      const response = await JoinEventApi(data);
      console.log("response", response);
      console.log("response", response?.data?.Message);
      setStatus(response?.data?.ErrorCode);
      setErrorMessage(response?.data?.Message);
      if (response?.data?.ErrorCode === 0) {
        getProfile();

        setTimeout(() => {
          setOpen(false);
          setErrorMessage("");
          setJoinGroup("");
        }, 2000);

        // getProfile()
      }
    }
  };

  const handleChange = (e) => {
    setGroup(e.target.value);
    setError("");
    setErrorMessage("");
  };
  const handleOpenForCreate = () => {
    // setIsCreate(true)
    setEventDate("");
    setEventName("");
    setOpen(true);
    setIsCreate(true);
  };
  const handleOpenForCreate1 = () => {
    // setIsCreate(true)
    setEventDate("");
    setEventName("");
    setCardOpen(true);
    setIsCreate(true);
  };
  const handleOpenForCreate2 = () => {
    // setIsCreate(true)
    setEventDate("");
    setEventName("");
    setEditOpen(true);
    setIsCreate(true);
  };

  const handleOpenForJoin = () => {
    setIsCreate(false);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setEditOpen(false);
    setCardOpen(false);
    setIsCreate(false);
    setJoinGroup("");
    setError("");
    setGroup("Select");
    setSlot("Select");
    setAlbumName("");
    setErrorMessage("");
    setStatus("");
    setIsEdit(false);
    setEventName("");
    setEventDate("");

    // setArea('')
    setVenue("");
    setVenueAl("Select");
    setCountry("");
    setCityName("Bengaluru, India");
    setEventProfile("");

    setErrorMessage("");
  };

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    borderColor: "#8CBAE8", // Set border color to black
    borderWidth: 1, // Set border width
    borderStyle: "solid", // Set border style
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "black",
    borderColor: "black",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
  }));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: 400,
    height: "480px",
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
  };

  const UserList = (item) => {
    const eventProps = item;
    navigate("/upload", { state: { eventProps } });
  };

  useEffect(() => {
    getProfile();
    getEvent();
  }, []);
  const getEvent = async (dates) => {
    setLoadss(true);
    try {
      const response = await GetEventApi(dates);
      console.log("hjhjh", response);
      setGetEventName(response?.data?.data || []);
    } catch (error) {
      console.error("Error fetching event data:", error);
    } finally {
      setLoadss(false);
    }
  };

  // const getPrivated = async (dates) => {
  //   try {
  //     const response = await getPrivateGroup(dates)
  //     console.log("responseuser", response)
  //     setGetEventNamed(response?.data?.data)
  //     console.log(response?.data?.data, 'responsedata1234');
  //   } catch (error) {
  //   }
  // }

  // const getJoinEvents = async (item) => {
  //   console.log("item", item)
  //   const data = item?.map(item => item.eventCode)

  //   console.log("data123", data)
  //   const response = await getJoinGroup(data)
  //   console.log("response256", response)

  //   setGetJoinEventName(response?.data?.data || [])
  // }

  const publicGroup = () => {
    navigate("/publicGroup");
  };

  const payment = () => {
    navigate("/subscription");
  };
  //----------------------Date filter ------------------//
  const onDate = (data) => {
    console.log("fsdfgfdgdfgdfgdf", data);
    const dat = data.split("#");
    console.log("hhh", dat);
    const dates = {
      startDate: dat[0],
      endDate: dat[1],
      search: "",
    };

    getEvent(dates);
    getPrivateGroup(dates);
  };
  const today = new Date().toISOString().split("T")[0];
  // startDate=2024-03-11&endDate=2024-03-11
  // ----------------End Date filter----------------------//
  // -----------------Delete MY events--------------------//
  const DleteMyEvents = async (item) => {
    try {
      console.log("kkkkkkkkkkkkkkkkkkkk");
      const response = await deleteMyEvent(item);
      console.log("Deleteresponse:", response);
      setEventProfile("");
      setEventName("");
      getEvent();
      getPrivateGroup();
      getProfile();
      setDeleteItem("");
      setSuccessMessage({
        message: "Album deleted successfully",
        status: true,
      });
      setTimeout(() => {
        setSuccessMessage({ message: "", status: null });
      }, 3000);
    } catch (err) {
      console.log("Error in DleteMyEvents:", err);
      setErrorMessage({ message: "Failed to delete event", status: false });
      setTimeout(() => {
        setErrorMessage({ message: "", status: null });
      }, 3000);
    }
  };
  //---------------end delete event---------------------------//
  //----------------Edit My Event-----------------------------//

  //----------------------------------------------------------//

  const handleDeleteOpen = (item) => {
    setOpens(true);
    // DleteMyEvents
    setDeleteItem(item);
  };

  const handledeleteclose = () => {
    setDeleteItem("");
    setOpens(false);
  };

  const handleAgree = () => {
    DleteMyEvents(deleteItem);
    setOpens(false);
  };

  //-------------------------------------------------------------//
  //-----------------search onChange ----------------------------//

  // useEffect(() => {
  //   const data = {
  //     startDate: "",
  //     endDate: "",
  //     search: searchValue
  //   };
  //   getEvent(data);
  // }, [searchValue]);

  const handleSearchChange = (data) => {
    console.log("data", data);
    setSearchValue(data);
    const datas = {
      startDate: "",
      endDate: "",
      search: data,
    };
    console.log(data);

    getEvent(datas);
  };

  //------------------------------------------------------------//
  //---------------sticky scroll Header -----------------------//
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setIsScrolling(true);
    let scrollTimeout;
    clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(() => {
      setIsScrolling(false);
    }, 200);
  };
  //-----------------------------------------------------------------------//

  console.log("area", area);
  const handleLocationChange = (event) => {
    const selectedValue = event.target.value;
    const selectedLocation = locations.find(
      (location) => location.value === selectedValue
    );
    setArea(selectedLocation?.display || "");
    setCityName(selectedValue);
    setError("");
    setErrorMessage("");
  };
  const [selectedImages, setselectedImages] = useState('');
  const [ImageUrl, setImageUrl] = useState('');


  const handleImageChange = (event) => {
    const selectedImages = event.target.files[0];
    setselectedImages(selectedImages);
    if (selectedImages) {
      const url = URL.createObjectURL(selectedImages);
      setBannerImage(selectedImages);
      setImageUrl(url);
      // Save the image URL in local storage
      localStorage.setItem("albumImage", url);
    }
  };
  const handleBannerRemove = () => {
    // setFile(null);

    setBannerImage(null);
    setImageUrl(null);
    // Remove the image URL from local storage
    localStorage.removeItem("albumImage");  // This should clear the file and remove the image
  };

  const handleFileRemove = () => {
    setAlbumImage(null);
    setFileUrl(null);
    // Remove the image URL from local storage
    localStorage.removeItem("albumImage");    // This should clear the file and remove the image
  };
  const [selectedFile, setselectedFile] = useState('');
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setselectedFile(selectedFile);
    if (selectedFile) {
      const url = URL.createObjectURL(selectedFile);
      setAlbumImage(selectedFile);
      setFileUrl(url);
      // Save the image URL in local storage
      localStorage.setItem("albumImage", url);
    }
  };
  const locations = [
    { display: "Select", value: "Select" },
    { display: "Bengaluru, India", value: "Bengaluru, India" },
    { display: "Canada", value: "Canada" },
    { display: "Cochin, India", value: "Cochin, India" },
    { display: "Europe", value: "Europe" },
    { display: "Europe - Amsterdam", value: "Amsterdam, Netherlands" },
    { display: "Europe - Bad Antogast", value: "Bad Antogast, Germany" },
    { display: "Europe - French Riviera", value: "French Riviera, France" },
    { display: "New Delhi, India", value: "New Delhi, India" },
    {
      display: "Thiruvananthapuram, India",
      value: "Thiruvananthapuram, India",
    },
    { display: "USA - Bay Area", value: "Bay Area, USA" },
    { display: "USA - Boone", value: "Boone, USA" },
    { display: "USA - DC", value: "DC, USA" },
    { display: "USA - Los Angeles", value: "Los Angeles, USA" },
  ];
  const formatDate = (date) => {
    const today = new Date();
    const eventDate = new Date(date);

    const isToday = today.toDateString() === eventDate.toDateString();
    const isYesterday =
      today.getDate() - 1 === eventDate.getDate() &&
      today.getMonth() === eventDate.getMonth() &&
      today.getFullYear() === eventDate.getFullYear();

    const diffInTime = today.getTime() - eventDate.getTime();
    const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));

    const isThisMonth =
      today.getMonth() === eventDate.getMonth() &&
      today.getFullYear() === eventDate.getFullYear();
    console.log("sdfgh");

    const isThisYear = today.getFullYear() === eventDate.getFullYear();

    if (isToday) return "Today";
    if (isYesterday) return "Yesterday";
    if (diffInDays <= 7) return "Last 7 days";
    if (isThisMonth) return "This Month";
    if (isThisYear) return "This Year";

    return "Earlier";
  };
  const groupByDate = (events) => {
    return events.reduce((acc, event) => {
      const dateGroup = formatDate(event.eventDate);
      if (!acc[dateGroup]) {
        acc[dateGroup] = [];
      }
      acc[dateGroup].push(event);
      return acc;
    }, {});
  };

  const [groupedEvents, setGroupedEvents] = useState({});

  useEffect(() => {
    setGroupedEvents(groupByDate(geteventName));
  }, [geteventName]);

  console.log("group event 12eeeee34", groupedEvents);


  const renderEvents = () => {
    if (loadss) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh", // Ensure the height covers the main content area
          }}
        >
          <CircularProgress color="inherit" />
        </div>
      );
    }

    // Check if there are any events in groupedEvents
    const hasEvents = Object.keys(groupedEvents).length > 0;

    if (!hasEvents) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            // alignItems: "center",
            width: "100%",
            paddingTop: "45px",
          }}
        >
          <div
          // style={{
          //   display: "flex",
          //   justifyContent: "center",
          //   alignItems: "center",
          // }}
          >
            <Typography variant="h6" color="textSecondary">
              No Results Found
            </Typography>
          </div>
        </div>
      );
    }

    return Object.entries(groupedEvents).map(([date, events], dateIndex) => (
      <React.Fragment key={dateIndex}>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            style={{
              paddingLeft: "35px",
              textAlign: "center",
              fontWeight: "bold",
              display: "flex",
              width: "100px",
            }}
          >
            {date}
          </Typography>
        </Grid>
        {events.map((item, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <ThemeProvider theme={theme}>
              <Stack
                direction="column"
                alignItems="center"
                sx={{ width: "100%" }}
              >
                <div
                  style={{
                    paddingTop: "5px",
                    height: "100%",
                    overflow: "hidden",
                    paddingBottom: "40px",
                    width: "100%", // Ensure the inner content doesn't exceed its container
                  }}
                >
                  <MyCardEvent
                    item={item}
                    onCardClick={UserList}
                    defaultImage={defaultImage}
                    editEvent={editEvent}
                    handleDeleteOpen={handleDeleteOpen}
                    key={item.eventCode}
                    index={index}
                    copiedMap={copiedMap}
                    handleCopylink={handleCopylink}
                  />
                </div>
              </Stack>
            </ThemeProvider>
          </Grid>
        ))}
      </React.Fragment>
    ));
  };

  return (
    <div>
      {load ? <Loader open={load} /> : null}
      <DeleteModal
        open={opens}
        handleClose={handledeleteclose}
        handleAgrreDelete={handleAgree}
      />
      <Modal
        open={cardopen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        Backdrop={{ onClick: handleClose }}
      >
        <Box
          sx={{
            position: "relative",
            top: { xs: "20px", sm: "40px", md: "62px" }, // Responsive top position
            left: "0",
            overflowY: "auto",
            overflowX: "hidden",
            width: "100vw",
            height: "100vh",
            backdropFilter: "blur(10px)",
            background: "rgba(255, 255, 255, 0.15)",
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
            border: "1px solid rgba(255, 255, 255, 0.3)",
            borderRadius: "5px",
            // padding: { xs: "16px", sm: "24px", md: "32px" }, // Responsive padding
          }}
        >
          <Stack direction={"row"}>
            <Stack
              sx={{
                backgroundColor: "#6AA5FF",
                borderRadius: "50%",
                height: "30px",
                width: "30px",
                position: "absolute",
                right: {
                  xs: "30px",
                  sm: "40px",
                  md: "60px",
                  lg: "90px",
                  xl: "150px",
                }, // Responsive right position
                top: {
                  xs: "20px",
                  sm: "40px",
                  md: "60px",
                  lg: "80px",
                  xl: "100px",
                }, // Responsive top position
                cursor: "pointer",
              }}
            >
              <Box marginLeft={"3px"} marginTop={"2px"} onClick={handleClose}>
                <Clear />
              </Box>
            </Stack>
            <EventCards />
          </Stack>

          {errorMessage && (
            <Stack
              sx={{
                display: "flex",
                zIndex: 1,
                position: "sticky",
                top: 0,
                padding: "8px 0",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Alert severity={status === 200 ? "success" : "error"}>
                {errorMessage}
              </Alert>
            </Stack>
          )}
        </Box>
      </Modal>
      {/* this for top left craete album */}
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{ onClick: handleClose }}
      >
        <Box
          sx={{
            ...style,
            width: isMobile ? "90%" : isTablet ? "70%" : 400,
            maxHeight: "80vh",
            overflowY: "auto",
            overflowX: "hidden",
            padding: isMobile ? 2 : 3, // Add padding for mobile view
          }}
        >
          <Stack style={{ position: "absolute", right: 10, top: 10 }}>
            <Box onClick={handleClose}>
              <Clear />
            </Box>
          </Stack>
          <Stack
            style={{
              display: "flex",
              zIndex: 1,
              position: "sticky",
              top: 0,
              padding: "8px 0",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {loads && <CircularProgress color="inherit" />}
          </Stack>

          {errorMessage && (
            <Stack
              style={{
                display: "flex",
                zIndex: 1,
                position: "sticky",
                top: 0,
                padding: "8px 0",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Alert severity={status === 200 ? "success" : "error"}>
                {errorMessage}
              </Alert>
            </Stack>
          )}

          <Stack spacing={1} style={{ marginBottom: "6px", zIndex: 0 }}>
            <Typography style={{ fontSize: "18px", color: "black" }}>
              Album Type{" "}
            </Typography>
            <Select value={group} onChange={handleChange}>
              <MenuItem value={"Select"}>Select</MenuItem>
              <MenuItem value={"Public"}>Public</MenuItem>
              <MenuItem value={"Private"}>Private</MenuItem>
            </Select>
            {error === "Album type is required" && (
              <Typography
                style={{ color: "red", fontSize: "16px", textAlign: "left" }}
              >
                {error}
              </Typography>
            )}
          </Stack>
          <Stack spacing={1} style={{ marginBottom: "6px", zIndex: 0 }}>
            <Typography style={{ fontSize: "18px", color: "black" }}>
              Album Name{" "}
            </Typography>
            <TextField
              value={albumName}
              onChange={(e) => {
                setAlbumName(e.target.value);
                setError("");
                setErrorMessage("");
              }}
              placeholder="Enter Album Name"
              variant="outlined"
            />
            {error === "Album name is required" && (
              <Typography
                style={{ color: "red", fontSize: "16px", textAlign: "left" }}
              >
                {error}
              </Typography>
            )}
          </Stack>

          <Stack spacing={1} style={{ marginBottom: "6px" }}>
            <Typography style={{ fontSize: "18px", color: "black" }}>
              Venue{" "}
            </Typography>
            <TextField
              value={venue}
              onChange={(e) => {
                setVenue(e.target.value);
                setError("");
                setErrorMessage("");
              }}
              placeholder="Enter Venue"
              variant="outlined"
            />
            {error === "Venue is required" && (
              <Typography
                style={{ color: "red", fontSize: "16px", textAlign: "left" }}
              >
                {error}
              </Typography>
            )}
          </Stack>

          <Stack spacing={1} style={{ marginBottom: "6px" }}>
            <Typography
              style={{ fontSize: "18px", color: "black", textAlign: "left" }}
            >
              Venue Category{" "}
            </Typography>
            <Select
              value={venueAl}
              onChange={(e) => {
                setVenueAl(e.target.value);
                console.log(e.target.value);
                setError("");
                setErrorMessage("");
              }}
            >
              <MenuItem value={"Select"}>Select</MenuItem>
              <MenuItem value={"Darshan Line"}>Darshan Line</MenuItem>
              <MenuItem value={"Wedding Event"}>Wedding Event</MenuItem>
              <MenuItem value={"Others"}>Others</MenuItem>
            </Select>
            {error === "Venue is required" && (
              <Typography
                style={{ color: "red", fontSize: "16px", textAlign: "left" }}
              >
                {error}
              </Typography>
            )}
          </Stack>

          <Stack spacing={1} style={{ marginBottom: "6px" }}>
            <Typography style={{ fontSize: "18px", color: "black" }}>
              Slot
            </Typography>
            <Select value={slot} onChange={handleSlotChange}>
              <MenuItem value={"Select"}>Select</MenuItem>
              <MenuItem value={"Morning"}>Morning</MenuItem>
              <MenuItem value={"Evening"}>Evening</MenuItem>
              <MenuItem value={"Night"}>Night</MenuItem>
            </Select>
          </Stack>

          <Stack spacing={1} style={{ marginBottom: "6px" }}>
            <Typography
              style={{ fontSize: "18px", color: "black", textAlign: "left" }}
            >
              Place{" "}
            </Typography>
            <Select value={cityName} onChange={handleLocationChange}>
              {locations.map((location, index) => (
                <MenuItem key={index} value={location.value}>
                  {location.display}
                </MenuItem>
              ))}
            </Select>
            {error === "Area is required" && (
              <Typography
                style={{ color: "red", fontSize: "16px", textAlign: "left" }}
              >
                {error}
              </Typography>
            )}
          </Stack>

          <Stack spacing={1}>
            <Typography
              style={{ fontSize: "18px", color: "black", textAlign: "left" }}
            >
              Event Date{" "}
            </Typography>
            <TextField
              placeholder="Select Event Date"
              type="date"
              value={eventDate}
              onChange={(e) => {
                console.log(e.target.value);
                setEventDate(e.target.value);
                setError("");
                setErrorMessage("");
              }}
              style={{ width: "100%" }}
              inputProps={{ max: today }}
            />
            {error === "Event Date is required" && (
              <Typography
                style={{ color: "red", fontSize: "16px", textAlign: "left" }}
              >
                {error}
              </Typography>
            )}
          </Stack>

          <Button
            variant="contained"
            fullWidth
            style={{ marginTop: 20 }}
            onClick={handleEventSubmit}
          >
            {isEditData ? "Update" : "Create"}
          </Button>
        </Box>
      </Modal>
      {/* this for edit album  */}
      <Modal
        open={editopen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      // BackdropProps={{ onClick: handleClose }}
      >
        <Grid
          container
          // onClick={handleClose}
          sx={{
            position: "relative",
            top: { xs: "20px", sm: "40px", md: "62px" }, // Responsive top position
            left: "0",
            overflowY: "auto",
            overflowX: "auto",
            width: "100vw",
            height: "100vh",
            backdropFilter: "blur(10px)",
            background: "rgba(255, 255, 255, 0.15)",
            boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
            border: "1px solid rgba(255, 255, 255, 0.3)",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack style={{ position: "absolute", right: 10, top: 10 }}>
            <Box onClick={handleClose}>
              <Clear />
            </Box>
          </Stack>
          <Grid
            // marginTop={"100px"}
            marginBottom={"50px"}
            marginLeft={"50px"}
            marginRight={"20px"}
            item
            xs={11}
            sm={8}
            md={6}
            lg={4}
          >
            <Card
              style={{
                border: "1px solid #DBDBDE",
                padding: "20px",
                marginTop: "10px",
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {!AlbumImage && (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  border="2px dashed grey"
                  borderRadius="10px"
                  minWidth={isSmallScreen ? "50%" : "75%"}
                  height={"20px"}
                  sx={{
                    mx: "auto",
                    mb: 2,
                    backgroundColor: "#ECF8FB",
                    p: 6,
                    mt: 1,
                  }}
                >
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="file-upload-albumImage"
                    type="file"
                    name="uploadImage"
                    onChange={handleFileChange}
                  />
                  <label htmlFor="file-upload-albumImage">
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      sx={{ cursor: "pointer", textAlign: "center" }}
                    >
                      <UploadFileRounded sx={{ fontSize: 48, mb: 2 }} />
                      <Typography variant="body1">
                        Choose Album Image{" "}
                      </Typography>
                    </Box>
                  </label>
                </Box>
              )}

              {AlbumImage && (<Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{ position: "relative", width: "80%", mx: "auto" }}
              >
                {AlbumImage && (
                  <>
                    <img
                      src={selectedFile ? URL.createObjectURL(selectedFile) : AlbumImage}
                      alt="Uploaded File"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "200px",
                        borderRadius: "10px",
                        marginBottom: "16px",
                        position: "relative",
                      }}
                    />
                    <IconButton
                      onClick={handleFileRemove}
                      sx={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        color: "white",
                        padding: "5px",
                        borderRadius: "50%",
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.7)",
                        },
                      }}
                    >
                      <img
                        src={deleteicon}
                        alt="Delete"
                        style={{
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </IconButton>
                  </>
                )}
              </Box>
              )}


              {errorMessage && (
                <Stack
                  style={{
                    display: "flex",
                    zIndex: 1000,
                    position: "fixed",
                    top: 130,
                    left: 350,
                    padding: "8px 0",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Alert severity={status === 200 ? "success" : "error"}>
                    {errorMessage}
                  </Alert>
                </Stack>
              )}

              <form style={{ marginTop: "0px", width: "100%" }}>
                <Stack spacing={1} style={{ marginBottom: "6px", zIndex: 0 }}>
                  <Typography style={{ fontSize: "18px", color: "black" }}>
                    Album Type{" "}
                  </Typography>
                  <Select value={group} onChange={handleChange}>
                    <MenuItem value={"Select"}>Select</MenuItem>
                    <MenuItem value={"Public"}>Public</MenuItem>
                    <MenuItem value={"Private"}>Private</MenuItem>
                  </Select>
                  {error === "Album type is required" && (
                    <Typography
                      style={{
                        color: "red",
                        fontSize: "16px",
                        textAlign: "left",
                      }}
                    >
                      {error}
                    </Typography>
                  )}
                </Stack>
                <Stack spacing={0}>
                  <Typography
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "left",
                      marginTop: "5px",
                    }}
                  >
                    Album Name
                  </Typography>

                  <TextField
                    placeholder="Enter Your Album Name"
                    value={albumName}
                    onChange={(e) => {
                      setAlbumName(e.target.value);
                      setErrorMessage("");
                    }}
                    fullWidth
                  />

                  <Typography
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "left",
                      marginTop: "1.3rem",
                    }}
                  >
                    Event Place
                  </Typography>
                  <TextField
                    placeholder="Enter your event place eg(church street)"
                    value={venue}
                    onChange={(e) => {
                      setVenue(e.target.value);
                      setErrorMessage("");
                    }}
                    fullWidth
                  />

                  <Grid container spacing={2} mt={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        style={{
                          fontSize: "18px",
                          color: "black",
                          textAlign: "left",
                        }}
                      >
                        Event Timing
                      </Typography>
                      <Select
                        value={slot}
                        onChange={(e) => {
                          setSlot(e.target.value);
                          setErrorMessage("");
                        }}
                        fullWidth
                      >
                        {/* <MenuItem value={"Select"}>Select</MenuItem> */}
                        <MenuItem value={"Morning"}>Morning</MenuItem>
                        <MenuItem value={"Afternoon"}>Afternoon</MenuItem>
                        <MenuItem value={"Evening"}>Evening</MenuItem>
                        <MenuItem value={"Night"}>Night</MenuItem>
                      </Select>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography
                        style={{
                          fontSize: "18px",
                          color: "black",
                          textAlign: "left",
                        }}
                      >
                        Event Category
                      </Typography>
                      <Select
                        value={venueAl}
                        onChange={(e) => setVenueAl(e.target.value)}
                        fullWidth
                      >
                        <MenuItem value={"Select"}>Wedding</MenuItem>
                        <MenuItem value={"Darshan Line"}>Hikking</MenuItem>
                        <MenuItem value={"Wedding Event"}>Birthday</MenuItem>
                        <MenuItem value={"Others"}>Party</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>

                  <Typography
                    style={{
                      fontSize: "18px",
                      color: "black",
                      textAlign: "left",
                      marginTop: "1.3rem",
                    }}
                  >
                    Event Date
                  </Typography>
                  <TextField
                    type="date"
                    value={eventDate}
                    onChange={(e) => {
                      setEventDate(e.target.value);
                      setErrorMessage("");
                    }}
                    inputProps={{ max: today }}
                    fullWidth
                  />

                  <Stack
                    direction={"column"}
                    alignItems={"center"}
                    pt={4}
                    position={"relative"}
                  ></Stack>
                  {group === "Public" ? (
                    <Stack
                      direction={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      marginLeft={"5px"}
                    >
                      <Typography variant="h5" fontWeight={400}>

                      </Typography>
                      <Stack direction={"row"} spacing={0} mt={2}>
                        <Typography
                          variant="h6"
                          fontWeight={400}
                          mr={2}
                          paddingRight={0}
                          textAlign={"flex-start"}
                        // mb={4}
                        >
                          Do you want change your banner??
                        </Typography>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={isBanner}
                              onChange={() => setIsBanner(!isBanner)}
                              color="primary"
                            />
                          }
                          sx={
                            {
                              // padding: "3px",
                            }
                          }
                          label=""
                        />
                      </Stack>
                    </Stack>
                  ) : (
                    <></>
                  )}



                  {isBanner && (
                    <>
                      <Stack>

                        {!bannerImage && (
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            border="2px dashed grey"
                            borderRadius="10px"
                            width={"80%"}
                            height={"20px"}
                            sx={{
                              mx: "auto",
                              mb: 1,
                              backgroundColor: "#ECF8FB",
                              p: 6,
                              mt: 5,
                            }}
                          >
                            <input
                              accept="image/*"
                              style={{ display: "none" }}
                              id="file-upload-input"
                              type="file"
                              name="uploadImage"
                              onChange={handleImageChange}
                            />

                            <label htmlFor="file-upload-input">
                              <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                sx={{ cursor: "pointer", textAlign: "center" }}
                              >
                                <UploadFileRounded sx={{ fontSize: 48, mb: 2 }} />
                                <Typography variant="body1">
                                  Choose Banner Image
                                </Typography>
                              </Box>
                            </label>
                          </Box>
                        )}

                        {bannerImage && (<Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="center"
                          textAlign="center"
                          sx={{
                            marginTop: "20px",
                            position: "relative",
                            width: "80%",
                            mx: "auto",
                          }}
                        >
                          <img
                            src={selectedImages ? URL.createObjectURL(selectedImages) : bannerImage}
                            alt="Uploaded File"
                            style={{
                              maxWidth: "100%",
                              maxHeight: "200px",
                              borderRadius: "10px",
                              marginBottom: "16px",
                            }}
                          />

                          <IconButton
                            onClick={handleBannerRemove}
                            sx={{}}
                          >
                            <img
                              src={deleteicon}
                              alt="Delete"
                              style={{
                                position: "relative",
                                top: "-90px",
                                left: "10px",
                              }}
                            />
                          </IconButton>

                        </Box>)}

                        <Typography
                          color={"grey"}
                          fontSize={"12px"}
                          fontStyle={"italic"}
                        >
                          <li>
                            <strong>
                              {" "}
                              The image you choose that will be displaying as
                              your a banner
                            </strong>
                          </li>
                        </Typography>

                      </Stack>
                      <TextField
                        style={{
                          marginTop: "5%",
                        }}
                        placeholder="Add a tag line for your album"
                        value={bannerTitle}
                        onChange={(e) => setBannerTitle(e.target.value)}
                        required
                      />
                      <Typography
                        color={"grey"}
                        fontSize={"12px"}
                        fontStyle={"italic"}
                      >
                        <li>
                          <strong>
                            The tag line you using use for as .............
                          </strong>
                          {""}
                        </li>
                      </Typography>
                    </>
                  )}
                  <Stack direction={"row"} spacing={5}>
                    <Button
                      variant="Text"
                      // fullWidth
                      style={{
                        width: "40%",
                        fontWeight: "400",
                        fontSize: "15px",
                        marginTop: 20,
                        color: "black",
                        backgroundColor: "#DBDBDE",
                        textTransform: "none",
                        // textDecoration: "underline",
                      }}
                    onClick={()=>setEditOpen(false)}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      fullWidth
                      style={{ marginTop: 20, textTransform: "none" }}
                      onClick={handleEventSubmit}
                    >
                      Finish
                    </Button>
                  </Stack>
                </Stack>
              </form>
            </Card>
          </Grid>
        </Grid>
        {/* <Box
          sx={{
            ...style,
            width: isMobile ? "90%" : isTablet ? "70%" : 400,
            maxHeight: "80vh",
            overflowY: "auto",
            overflowX: "hidden",
            padding: isMobile ? 2 : 3, // Add padding for mobile view
          }}
        >
          <Stack style={{ position: "absolute", right: 10, top: 10 }}>
            <Box onClick={handleClose}>
              <Clear />
            </Box>
          </Stack>
          <Stack
            style={{
              display: "flex",
              zIndex: 1,
              position: "sticky",
              top: 0,
              padding: "8px 0",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {loads && <CircularProgress color="inherit" />}
          </Stack>

          {errorMessage && (
            <Stack
              style={{
                display: "flex",
                zIndex: 1,
                position: "sticky",
                top: 0,
                padding: "8px 0",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Alert severity={status === 200 ? "success" : "error"}>
                {errorMessage}
              </Alert>
            </Stack>
          )}

          <Stack spacing={1} style={{ marginBottom: "6px", zIndex: 0 }}>
            <Typography style={{ fontSize: "18px", color: "black" }}>
              Album Type{" "}
            </Typography>
            <Select value={group} onChange={handleChange}>
              <MenuItem value={"Select"}>Select</MenuItem>
              <MenuItem value={"Public"}>Public</MenuItem>
              <MenuItem value={"Private"}>Private</MenuItem>
            </Select>
            {error === "Album type is required" && (
              <Typography
                style={{ color: "red", fontSize: "16px", textAlign: "left" }}
              >
                {error}
              </Typography>
            )}
          </Stack>
          <Stack spacing={1} style={{ marginBottom: "6px", zIndex: 0 }}>
            <Typography style={{ fontSize: "18px", color: "black" }}>
              Album Name{" "}
            </Typography>
            <TextField
              value={albumName}
              onChange={(e) => {
                setAlbumName(e.target.value);
                setError("");
                setErrorMessage("");
              }}
              placeholder="Enter Album Name"
              variant="outlined"
            />
            {error === "Album name is required" && (
              <Typography
                style={{ color: "red", fontSize: "16px", textAlign: "left" }}
              >
                {error}
              </Typography>
            )}
          </Stack>

          <Stack spacing={1} style={{ marginBottom: "6px" }}>
            <Typography style={{ fontSize: "18px", color: "black" }}>
              Venue{" "}
            </Typography>
            <TextField
              value={venue}
              onChange={(e) => {
                setVenue(e.target.value);
                setError("");
                setErrorMessage("");
              }}
              placeholder="Enter Venue"
              variant="outlined"
            />
            {error === "Venue is required" && (
              <Typography
                style={{ color: "red", fontSize: "16px", textAlign: "left" }}
              >
                {error}
              </Typography>
            )}
          </Stack>

          <Stack spacing={1} style={{ marginBottom: "6px" }}>
            <Typography
              style={{ fontSize: "18px", color: "black", textAlign: "left" }}
            >
              Venue Category{" "}
            </Typography>
            <Select
              value={venueAl}
              onChange={(e) => {
                setVenueAl(e.target.value);
                console.log(e.target.value);
                setError("");
                setErrorMessage("");
              }}
            >
              <MenuItem value={"Select"}>Select</MenuItem>
              <MenuItem value={"Darshan Line"}>Darshan Line</MenuItem>
              <MenuItem value={"Wedding Event"}>Wedding Event</MenuItem>
              <MenuItem value={"Others"}>Others</MenuItem>
            </Select>
            {error === "Venue is required" && (
              <Typography
                style={{ color: "red", fontSize: "16px", textAlign: "left" }}
              >
                {error}
              </Typography>
            )}
          </Stack>

          <Stack spacing={1} style={{ marginBottom: "6px" }}>
            <Typography style={{ fontSize: "18px", color: "black" }}>
              Slot
            </Typography>
            <Select value={slot} onChange={handleSlotChange}>
              <MenuItem value={"Select"}>Select</MenuItem>
              <MenuItem value={"Morning"}>Morning</MenuItem>
              <MenuItem value={"Evening"}>Evening</MenuItem>
              <MenuItem value={"Night"}>Night</MenuItem>
            </Select>
          </Stack>

          <Stack spacing={1} style={{ marginBottom: "6px" }}>
            <Typography
              style={{ fontSize: "18px", color: "black", textAlign: "left" }}
            >
              Place{" "}
            </Typography>
            <Select value={cityName} onChange={handleLocationChange}>
              {locations.map((location, index) => (
                <MenuItem key={index} value={location.value}>
                  {location.display}
                </MenuItem>
              ))}
            </Select>
            {error === "Area is required" && (
              <Typography
                style={{ color: "red", fontSize: "16px", textAlign: "left" }}
              >
                {error}
              </Typography>
            )}
          </Stack>

          <Stack spacing={1}>
            <Typography
              style={{ fontSize: "18px", color: "black", textAlign: "left" }}
            >
              Event Date{" "}
            </Typography>
            <TextField
              placeholder="Select Event Date"
              type="date"
              value={eventDate}
              onChange={(e) => {
                console.log(e.target.value);
                setEventDate(e.target.value);
                setError("");
                setErrorMessage("");
              }}
              style={{ width: "100%" }}
              inputProps={{ max: today }}
            />
            {error === "Event Date is required" && (
              <Typography
                style={{ color: "red", fontSize: "16px", textAlign: "left" }}
              >
                {error}
              </Typography>
            )}
          </Stack>

          <Button
            variant="contained"
            fullWidth
            style={{ marginTop: 20 }}
            onClick={handleEventSubmit}
          >
            {isEditData ? "Update" : "Create"}
          </Button>
        </Box> */}
      </Modal>

      <div
        style={{
          position: "sticky",
          top: "60px",
          zIndex: "999",
          width: "100%",
          // transition: "top 0.3s",
          // top: isScrolling ? "-100px" : "70px",
        }}
      >
        <div>
          <Card
            style={{
              marginTop: 10,
              width: "100%",
              backgroundColor: "#F4FAFF",
              // border: "1px solid #D4D4D4",
            }}
            elevation={0}
          >
            {isMobileOrTablet ? (
              <Grid container spacing={2} sx={{ padding: "10px" }}>
                <Grid item xs={12} sm={12}></Grid>
                <Grid item xs={12} sm={12}>
                  <RangeDatePicker width={"100%"} filterDate={onDate} />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  {/* <Button
                    height="20px"
                    fullWidth
                    variant="text"
                    onClick={handleOpenForCreate}
                    style={{
                      textTransform: "none",
                      fontWeight: "600",
                      backgroundColor: "white",
                      border: "1px solid #D4D4D4",
                    }}
                  >
                    <img
                      style={{ marginRight: "10px" }}
                      src={createAlbumICon}
                      alt=""
                      srcset=""
                    />
                    Create Album
                  </Button> */}
                  <IconButton aria-label="filter">
                    {/* <FilterListIcon /> */}
                  </IconButton>
                  <SearchField
                    width={"400px"}
                    searchProps={handleSearchChange}
                    placeholder=""
                  />
                  {/* <TextField onChange={handleChange} /> */}
                </Grid>
              </Grid>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",

                  justifyContent: "space-between",
                  padding: 5,
                  // width: "100%"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: 10,
                  }}
                >
                  {/* <Grid>
                    <Button
                      height="20px"
                      fullWidth
                      variant="text"
                      onClick={handleOpenForCreate}
                      style={{
                        textTransform: "none",
                        fontWeight: "600",
                        backgroundColor: "white",
                        border: "1px solid #D4D4D4",
                      }}
                    >
                      <img
                        style={{ marginRight: "10px" }}
                        src={createAlbumICon}
                        alt=""
                        srcset=""
                      />
                      Create Album
                    </Button>
                  </Grid> */}
                </div>
                <div>
                  <Stack
                    direction={"row"}
                    gap={2}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-end",
                      padding: 10,
                    }}
                  >
                    {" "}
                    <NotificationModal />
                    {" "}
                    <RangeDatePicker width={"100%"} filterDate={onDate} />
                    <SearchField
                      width="400px"
                      searchProps={handleSearchChange}
                      // placeholder="Search, Album name"
                      style={{ backgroundColor: "white" }}
                    />
                  </Stack>
                </div>
              </div>
            )}
          </Card>
          {/* <h2>sdfghj</h2> */}
          {SuccessMessage.status && (
            <Alert
              severity={SuccessMessage.status ? "success" : "error"}
              style={{ marginBottom: 10 }}
            >
              {SuccessMessage.message}
            </Alert>
          )}
        </div>
      </div>
      <Stack
        marginTop="60px"
        marginLeft={2}
        spacing={2}
        alignItems={isMobile ? "flex-start" : "center"}
      >
        {/* First Section */}
        <Stack
          direction="column"
          alignItems={isMobile ? "flex-start" : "center"}
        >
          {/* <PhotoAlbumIcon fontSize="large" /> */}
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "600",
              color: "#3974CB",
              mt: 2,
            }}
          >
            My Uploads
          </Typography>
        </Stack>

        {/* Second Section */}
        <Typography
          variant="body1"
          sx={{
            fontSize: isSmallScreen ? "1em" : "0.em",
            textAlign: isSmallScreen ? "left" : "center", // Corrected textAlign
            mt: 5,
            mb: 7,
            maxWidth: 800,
            paddingLeft: isSmallScreen ? "" : 0, // Adjusts padding on small screens
          }}
        >
          Albums created by you are displayed below.
        </Typography>

        {/* Button Section */}
        <Grid
          container
          justifyContent={isMobile ? "flex-start" : "center"} // Centers button on mobile
        >
          <Button
            height="20px"
            // fullWidth
            variant="contained"
            onClick={handleOpenForCreate1}
            style={{
              // alignItems: "center",
              // justifyContent: "center",
              textTransform: "none",
              fontWeight: "600",
              color: "white",
              backgroundColor: "#2167CF",
            }}
          >
            <img
              style={{ marginRight: "10px" }}
              src={createAlbumIConwhite}
              alt=""
              srcSet=""
            />
            Create Album
          </Button>
        </Grid>
      </Stack>

      <div style={{ marginTop: "10px", position: "relative" }}>
        {loadss ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "20px",
            }}
          >
            <CircularProgress color="inherit" />
          </div>
        ) : (
          <Box
            maxWidth="1400px"
            margin="0 auto"
            padding={isMobileOrTablet ? 1 : 2}
          >
            <Grid container spacing={2} marginTop={isMobileOrTablet ? 1 : null}>
              {renderEvents()}
            </Grid>
          </Box>
        )}
      </div>

      {/* <div>{renderEvents(groupedEvents)}</div> */}
    </div>
  );
}
