// actions.js
import { ADD_ADDUSERIMAGE_REQUEST, ADD_PROFILE_REQUEST, ADD_REGISTERDATA_REQUEST, CLEAR_REDUX_REQUEST, UPDATE_PROFILE_REQUEST } from './actionType';

export const addProfileRequest = (payload) => ({ type: ADD_PROFILE_REQUEST, payload });
export const updateProfileRequest = () => ({ type: UPDATE_PROFILE_REQUEST });
export const clearRedux = () => ({ type: CLEAR_REDUX_REQUEST });
export const UPDATE_PROFILE_IMAGE = 'UPDATE_PROFILE_IMAGE';
export const  updateUserImage= (payload) => ({
   
    type: ADD_ADDUSERIMAGE_REQUEST,
    payload
 });

 export const  addRegisterData= (payload) => ({
   
    type: ADD_REGISTERDATA_REQUEST,
    payload
 });

 export const updateProfileImage = (imageUrl) => ({
   type: UPDATE_PROFILE_IMAGE,
   payload: imageUrl,
 });
