import React, { useState } from "react";
import {
  Card,
  CardMedia,
  Typography,
  Stack,
  Box,
  Button,
  Grid,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import deleted from "../../images/delete-icon.svg";
import edited from "../../images/edit-icon.svg";
import redlocation from "../../images/location-icon.svg";

const CardEvent = ({
  item,
  onCardClick,
  defaultImage,
  editEvent,
  handleDeleteOpen,
}) => {
  return (
    <Card
      sx={{
        marginBottom: "",
        height: "100%",
        maxWidth: 320,
        maxHeight: 296,
        border: "1px solid #D4D4D4",
        borderRadius: "16px",
        position: "relative",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
        "&:hover": {
          transform: "scale(1.05)",
          // boxShadow: "0 8px 16px rgba(0, 0, 0, 0.3)",
        },
        "&:hover .photo-count": {
          opacity: 1,
        },
        "&:hover .overlay": {
          opacity: 0.7,
          backdropFilter: "blur(10px)",
        },
      }}
      elevation={0}
    >
      <div
        style={{ padding: "10px", overflow: "hidden", position: "relative" }}
      >
        <Typography
          variant="body2"
          className="overlay"
          sx={{
            fontWeight: "bold",
            fontSize: "0.8em",
            position: "absolute",
            top: "8px",
            right: "8px",
            color: "#434343",
            backgroundColor: "white",
            borderRadius: "3px",
            padding: "5px 8px",
            zIndex: 1,
          }}
        >
          {new Date(item?.eventDate).toLocaleDateString("en-GB")}
        </Typography>
        <CardMedia
          component="img"
          height="100%"
          image={item?.imageUrl || defaultImage}
          alt={item?.name}
          style={{ objectFit: "cover", borderRadius: "5px", cursor: "pointer" }}
          onClick={() => onCardClick(item)}
        />
        <Typography
          className="overlay"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            opacity: 1,
            transition: "opacity 0.3s ease-in-out",
            cursor: "pointer",
          }}
          onClick={() => onCardClick(item)}
        />
        <Typography
          className="photo-count"
          variant="h8"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "#ffffff",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            borderRadius: "8px",
            padding: "8px 16px",
            opacity: 0,
            transition: "opacity 0.3s ease-in-out",
          }}
        >
          {item?.totalUploadImages || "No"} Photos
        </Typography>
      </div>
      <Stack direction="column" spacing={1} sx={{ flex: 1, padding: 2 }}>
        <div
          style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
        >
          <Typography
            gutterBottom
            variant="h6"
            sx={{
              fontSize: "16px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontWeight: 600,
              flex: 1,
            }}
          >
            {item?.name} &nbsp; | &nbsp; {item?.venueAlias}
          </Typography>
          {/* <Stack direction={"row"} style={{ marginTop: -5 }}>
    <IconButton
      onClick={() => handleCopylink(item?.eventCode, index)}
      aria-label="Copy Email Key"
      style={{ cursor: "pointer" }}
    >
      <ContentCopyIcon />
    </IconButton>
    <div style={{ marginTop: 10 }}>
      {copiedMap[index] && <span style={{ color: "green" }}>Copied!</span>}
    </div>
  </Stack> */}
        </div>
        <Stack direction="row" alignItems="center">
          <img src={redlocation} alt="" />
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              fontWeight: 600,
              fontSize: "12px",
              color: "#425466",
            }}
          >
            &nbsp; {item?.city} &nbsp; | &nbsp; {item?.slot}
          </Typography>
        </Stack>
      </Stack>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          padding: "2px 10px",
        }}
      >
        {/* <Stack direction={"row"} spacing={4} marginRight={3} paddingBottom={1}>
      <img src="" alt="" />
      <img
        alt='edit'
        src={edited}
        onClick={(event) => {
          event.stopPropagation();
          editEvent(item);
        }}
        style={{ cursor: 'pointer', marginRight: '5px' }}
      />
      <img
        alt='delete'
        src={deleted}
        onClick={(event) => {
          event.stopPropagation();
          handleDeleteOpen(item);
        }}
        style={{ cursor: 'pointer' }}
      />
    </Stack> */}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: "10px",
          borderTop: "1px solid #ddd",
        }}
      >
        <Button
          variant="outline"
          style={{
            fontSize: "12px",
            color: "#425466",
            fontWeight: 600,
            backgroundColor: "#F3F9FF",
          }}
          sx={{
            width: "100%",
            "&:hover": {
              color: "black",
              backgroundColor: "#4992FF",
            },
          }}
          onClick={() => onCardClick(item)}
        >
          Show Photos
        </Button>
      </Box>
    </Card>
  );
};

export default CardEvent;
