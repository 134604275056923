// saga.js
import { takeEvery, put, call } from 'redux-saga/effects';
import { ADD_PROFILE_REQUEST, UPDATE_PROFILE_REQUEST } from './actionType';
// import { addProfileData, updateProfile } from './userSlice';
import apiInstance from '../API/baseApi';
import { addProfileData,updateProfile } from './reducer';

function* AddProfile(action) {

  console.log("Action", action);
  try {
    const loginType="appointment"
    const userData={email:action.payload.email}
    const response = yield call(apiInstance.post,`/Auth/OtpVerify?loginType=${loginType}`, userData);
    const data = response.data;
    console.log("AddProfile action is called", data);

    let  userType=""
     if(action.payload.photoGrpherExist===true){
      userType="photographer"
     }
     else{
      userType=response.data.userType
     }
  
    const UserImage=data?.data?.imageUrl
    const UserId=data?.data?.AdditionalUsers[0]
    console.log("UserId1234",UserId)
    const datas={data,userType,UserImage,UserId }
    console.log('login data', datas);
   
    yield put(addProfileData(datas)); 
  } catch (error) {
    console.error("AddProfile error", error);
    yield put(addProfileData(error?.response?.data?.Message));
  }
}


function* UpdateProfile() {
  console.log("Update");
  try {
    const accessToken = localStorage.getItem("accessToken");
    const userId = localStorage.getItem("userId");
    const config = {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    };
    const response = yield call(apiInstance.get, `/Auth/profile?id=${userId}`, config);
    const data = response.data;
    console.log("UpdateProfile action is called", data);
    yield put(updateProfile(data)); // Dispatch the action to update the state
  } catch (error) {
    console.error("UpdateProfile error", error);
  }
}

function* ProfileSaga() {
  yield takeEvery(ADD_PROFILE_REQUEST, AddProfile);
  yield takeEvery(UPDATE_PROFILE_REQUEST, UpdateProfile);
}

export default ProfileSaga;
