// import { Outlet } from "react-router";
// import Header from "../../Screen/Header/header";
// import SideBar from "./sidebar/AdminSidebar";
// import '../../css/AdminLayout.css';
// import { useState } from "react";
// function AdminLayout(){
//     const [isSidebarOpen, setIsSidebarOpen] = useState(false);

//     const toggleSidebar = () => {
//       setIsSidebarOpen(!isSidebarOpen);
//     };
// return(
//     <div className="AdminLayou-body">
//         <div>
//            <Header/> 
//         </div>
//         <div className="AdminLayou-container">
//         <button className="menu-button" onClick={toggleSidebar}>
//           Menu
//         </button>
//         <div className={`sidebar-wrapper ${isSidebarOpen ? 'show' : ''}`}>
//           <SideBar />
//         </div>
//            <div style={{margin:'20px 10px 0px 20px'}}>
//             <p>njkjhnkjn</p>
//             <button  onClick={toggleSidebar}>
//         Menu
//       </button>
//               <Outlet/>
       
//            </div>
//         </div>
//     </div>
// )
// }
// export default AdminLayout;


import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../../Screen/Header/header';
import SideBar from './sidebar/AdminSidebar';
import '../../css/AdminLayout.css';
import {useTheme, useMediaQuery } from "@mui/material";
function AdminLayout() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const theme = useTheme();
    const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('sm'));
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        console.log(!isSidebarOpen);
    };

    return (
        <div className="AdminLayou-body">
            <div style={{width:200}}> 
                <Header sidebarOpen={toggleSidebar}/>
            </div>
            <div className="AdminLayou-container">
                
                {
                    isSidebarOpen ?
                    <div style={{width:'200px'}}>
                    <SideBar onClik={toggleSidebar}/>
                    </div>
                    :
                    <div style={{width:'200px'}} className="sidebar-wrapper">
                       <SideBar />
                    </div>
                }
                <div style={{margin:'20px 10px 0px ',width:"100%" , height:"100vh",marginLeft:isMobileOrTablet ? 25: 120}}>
                    <Outlet />
                </div>
                <div className="small-line"></div>
            </div>
        </div>
    );
}

export default AdminLayout;
