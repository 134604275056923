import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Avatar } from '@mui/material';
import styles from '../../css/AvatarLoader.module.css';
import Loading from './Loading';

const AvatarDetectLoader = ({userName,image}) => {
  const [seconds, setSeconds] = useState(0);


  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    const min = minutes===0? '': `${String(minutes).padStart(2, '0')}m:`;
    return `${min}${String(seconds).padStart(2, '0')}s`;
  };

  const text = "Searching";
  const text2 = "Virat"

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box
        position="relative"
        display="inline-flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress
          variant="determinate"
          value={100}
          size={78}
          thickness={5}
          style={{ color: '#00C3FF' }}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          size={78}
          thickness={5}
          style={{
            color: '#297EFF',
            position: 'absolute',
          }}
        />
        <Avatar
          src={image}
          alt="Center Image"
          style={{
            width: 70,
            height: 70,
            position: 'absolute',
          }}
        />
      </Box>
      <div style={{display:'flex',flexDirection:'row',margin:'10px 0px'}}>
      <Loading text={`Searching ${userName}'s photos`} />
      </div>
   
      <div className={styles.animatedText}>
        {formatTime(seconds)}
      </div>
    </div>


  );
};

export default AvatarDetectLoader;
