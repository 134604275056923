

import React, { useEffect, useState } from "react";
import {
  Typography,
  TableCell,
  Backdrop,
  CircularProgress,
  useTheme, useMediaQuery, Stack,


} from "@mui/material";
import { AccountBalanceOutlined, AccountCircle, DeleteOutlined, EditOutlined, GifBoxOutlined, Group, Refresh, RefreshSharp } from "@mui/icons-material";

import myImage from "../../../images/nodata.png"

import {
  TableCom,
  THead,
  TCell,
  TBody,
  Trow,
} from "../../Component/table"
import { getEventListApi, } from "../../../API/Admin";
import Menus from "../../Component/menu";
import { SearchField } from "../../Component/SerachField";
import { useLocation, useNavigate } from "react-router";




const PhotographerDetailes = () => {
  const [photographerData, setPhotographerData] = useState([]);
  const [metaPage, setmetaPage] = useState(1);
  const [profile,setProfile] = useState('');
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('sm'));
  const [page, setPage] = useState(1);
  const [skeliton, setskeliton] = useState(false);
  const navigate=useNavigate()
  const location=useLocation()
  // const userType =localStorage.getItem("userType")

  console.log("location?.state?.id",location?.state?.id)
  const userId=location?.state?.id
  const getPotgrapherData = async () => {
    const userType="photographer"
    setskeliton(true)   
    try {
      const response= await getEventListApi(userId)
      setPhotographerData(response?.data?.data)
     console.log("response",response)
      
       setskeliton(false)
    } catch (error) {
      console.log("error", error);
    }
  };


  useEffect(() => {
    getPotgrapherData()
  }, [page]);



  const goToPreviousPage = () => {
    setPage(page - 1);
  };

  const goToNextPage = async () => {
    try {
      const nextPage = page + 1;
      setPage(nextPage);
    } catch (error) {
      //console.log("error", error);
    }
  };

  
  const headers = [
    "Action",
    "Album Name",
    "Album Type ",
    "Created At",
    
      
  ];

  const deletePhotographer=()=>{

  }


  const albumCreated=()=>{
    navigate("/")
  }
  const menu = [
    // { name: "Employee List", icon: <Group/>, onClick: employeeList },
    // { name: 'Copy Referral Link', icon: <ContentCopyIcon />, onClick: handleCopylink },
    // { name: "Album created List", icon: <Group/>,  onClick:albumCreated},
    { name: 'Delete ', icon: <Group/>,onClick:deletePhotographer},
    
  
  ]

  const searchProps=(data)=>{
    console.log("data",data)
 }

  return (
    <div  style={{marginTop:10,marginInline:50}}>
      
      <>
        {skeliton ? (
        //   <Backdrop
        //     sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        //     open={skeliton} // Use skeliton instead of open
        //     onClick={handleClose}
        //   >
        //     <CircularProgress color="inherit" />
        //   </Backdrop>

        <CircularProgress color="inherit" />
        ) : photographerData?.length === 0|| photographerData===undefined ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
               <img src={myImage}  />
          </div>
        ) : (
          <>
          <Stack width={350} >
                <SearchField searchProps={searchProps}/>
            </Stack>
            
              <div style={{marginTop:10}}>
            
              <TableCom>
                <THead>

                  {headers?.map((header, index) => (
                    <TableCell
                      key={index}
                      style={{
                        borderRight: "1px solid white",
                        paddingRight: "10px",
                        fontWeight: "40%",
                        fontSize: "100%",
                        color: "aliceblue",
                        backgroundColor: "#0C2142"
                      }}
                    >
                      {header}
                    </TableCell>

                  ))}
                </THead>

             
                <TBody>
                  {photographerData?.map((value, index) => (

                    <Trow key={index} >
                      
                        <TCell>

                           
                        <Menus
                          menu={menu}
                          userdata={value}
                          types={"skill"}
                          setProfile={setProfile}
                        />
                        </TCell>



                      <TCell>
                       <Typography>{value.name ? value.name:"N/A"}</Typography>
          
                      </TCell>
                     
                      <TCell>
                      <Typography>{value.eventType? value.eventType:"N/A"}</Typography>
                      </TCell>


                      <TCell>
                      <Typography>{ value.date? value.date.split('T')[0]:"N/A" }</Typography>
                      </TCell>
                      
                      
                    </Trow>
                  ))}
                </TBody>
              </TableCom>
              </div>

              {/* <Stack
                direction="row"
                spacing={2}
                justifyContent={"flex-end"}
                style={{
                  marginTop: "5px",
                  marginBottom: "3px",
                  marginRight: "5px",
                }}
              >
                <Typography style={{ paddingTop: "5px", fontSize: "16px" }}>
                  Total Page : {metaPage}
                </Typography>
                <BasicButton
                  style={{ width: "250px" }}
                  onclick={goToPreviousPage}
                  value="Prev.."
                  disable={page === 1}
                />
                <Typography style={{ paddingTop: "5px", fontSize: "16px" }}>
                  {page}
                </Typography>
                <BasicButton
                  style={{ width: "250px" }}
                  onclick={goToNextPage}
                  value="Next"
                  disable={page === metaPage}
                />
              </Stack> */}
            
          </>
        )}
      </>

    </div>
  );
};

export default PhotographerDetailes;