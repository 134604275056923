import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
  useMediaQuery,
  useTheme,
  Stack,
} from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

const Carousel = ({ items, onCarouselClick }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const theme = useTheme();

  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const visibleCards = isLargeScreen ? 1 : isMediumScreen ? 2 : 1;
  const cardWidth = isLargeScreen ? 450 : isMediumScreen ? 300 : 250;
  const cardMargin = isLargeScreen ? 10 : isMediumScreen ? 12 : 8;

  const handlePrev = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setCurrentIndex((prevIndex) => Math.max(prevIndex - visibleCards, 0));
    }
  };

  const handleNext = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setCurrentIndex((prevIndex) =>
        Math.min(prevIndex + visibleCards, items.length - visibleCards)
      );
      if (items.length - currentIndex === 1) setCurrentIndex(0);
    }
  };

  const onCarouselClick1 = (item) => {
    onCarouselClick(item);
  };

  useEffect(() => {
    if (isTransitioning) {
      const transitionEnd = setTimeout(() => {
        setIsTransitioning(false);
      }, 500);

      return () => clearTimeout(transitionEnd);
    }
  }, [isTransitioning]);

  return (
    <Box display="flex" alignItems="center" overflow="hidden" width="100%">
      <IconButton onClick={handlePrev} disabled={isTransitioning}>
        <ArrowBackIos />
      </IconButton>
      <Box width="100%" overflow="hidden">
        <Box
          className="card-container"
          sx={{
            display: "flex",
            transition: isTransitioning ? "transform 0.5s ease-in-out" : "none",
            transform: `translateX(-${
              currentIndex * (cardWidth + cardMargin)
            }px)`,
            width: `${items.length * (cardWidth + cardMargin)}px`,
          }}
        >
          {items.map((item, index) => (
            <Card
              key={index}
              sx={{
                width: cardWidth,
                flexShrink: 0,
                marginRight: cardMargin,
                position: "relative",
                borderRadius: 2,
                marginTop: "15px",
              }}
              onClick={() => onCarouselClick1(item)}
            >
              <CardMedia
                component="img"
                height="250"
                image={item.bannerImage}
                alt={item.title}
                sx={{ position: "relative", borderRadius: "10px 10px 0 0" }}
              />
              <CardContent
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  color: "white",
                  textAlign: "center",
                  backdropFilter: "blur(15px)",
                  WebkitBackdropFilter: "blur(15px)",
                  background:
                    "linear-gradient(120deg, rgba(0, 0, 0, 0.7), rgba(255, 255, 255, 0.3))",
                  padding: "10px",
                  borderRadius: "0 0 10px 10px",
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.4)",
                  height: "40px",
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  component="div"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    textAlign: "left",
                    flexGrow: 1,
                    marginTop: "10px",
                    marginBottom: "4px",
                    marginLeft: "42px",
                  }}
                >
                  {item.frontImage}
                </Typography>

                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ flexWrap: "wrap" }}
                >
                  <CardMedia
                    component="img"
                    image={item?.imageUrl}
                    alt="icon"
                    sx={{
                      width: 30,
                      height: 30,
                      borderRadius: "30%",
                      border: "2px solid white",
                      flexShrink: 0,
                    }}
                  />
                  <Typography
                    variant="body2"
                    component="div"
                    sx={{ color: "#FFFFFF", fontSize: "12px" }} // Adjusted font size
                  >
                   
                    {item.albumName}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      backgroundColor: "#585F63",
                      padding: "4px 6px", // Reduced padding
                      borderRadius: "4px",
                      marginLeft: "auto",
                      fontSize: "12px", // Adjusted font size
                    }}
                  >
                    {item.totalUploadImages > 0
                      ? `${item.totalUploadImages} photos`
                      : "No photos"}
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          ))}
        </Box>
      </Box>
      <IconButton onClick={handleNext} disabled={isTransitioning}>
        <ArrowForwardIos />
      </IconButton>
    </Box>
  );
};

export default Carousel;
