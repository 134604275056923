import React, { useState,useEffect } from 'react'
import SearchIcon from '@mui/icons-material/Search';

import { InputAdornment, TextField,Stack } from '@mui/material';


export const SearchField =({searchProps,width,placeholder})=>{


  const [values,setValue]=useState('')
  const handleChange=(e)=>{
     console.log("Test",e.target.value)
     
    searchProps(e.target.value)
    setValue(e.target.value)
  }

  console.log("value",values)
    
               
  return (
   

    <Stack
    width={width ? width:300}
    style={{ display: "flex", justifyContent: "flex-end" }}
   
  >
    <TextField

     style={{justifyContent:'flex-end'}}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <div style={{marginTop:8}}>
            <SearchIcon /> 
          </div>
        </InputAdornment>
      ),
    }}
    sx={{
      '& .MuiInputBase-root': {
        height: '40px', 
      },
      '& .MuiInputBase-input': {
        padding: '1',
        
      },
    }}
    placeholder={placeholder}
    variant="outlined"
    fullWidth
     onChange={handleChange}
     value={values}
  />
</Stack>

  )
}
